import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

interface IInfoCard {
  title: string;
  value: string;
  icon: React.ReactNode;
}

const InfoCard = ({ title, value, icon }: IInfoCard) => {
  return (
    <>
      <Card style={{ width: "fit-content", height: "fit-content" }}>
        <CardContent style={{ padding: 0 }}>
          <Grid
            spacing={3}
            container
            style={{
              margin: 0,
              padding: "1rem 1.5rem",
              justifyContent: "center",
              gap: "1rem",
              width: "fit-content",
            }}
          >
            <Grid
              item
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Box
                sx={{
                  width: 70,
                  height: 70,
                  backgroundColor: "#D4E7BD",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                }}
              >
                {icon}
              </Box>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Typography gutterBottom variant="overline" color="textSecondary">
                {title}
              </Typography>
              <Typography variant="h6">{value}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default InfoCard;
