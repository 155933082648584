import { api } from '../utils/Api/apiClient';
import { TUser } from '../@types/user';

const GROUP_NAME = 'Tamarindo';

const getAllUsers = async () => {
  const url = `api/access/user`;
  return await api.get(url);
};

const getGroupUsers = async () => {
  const url = `api/access/group/users/${GROUP_NAME}`;
  return await api.get(url);
};

const createUser = async (username: string, password: string) => {
  const url = `api/access/user`;
  const body = {
    username: username,
    password: password
  };
  return await api.post(url, body);
};

const deleteUser = async (username: string) => {
  const url = `api/access/user/${username}`;
  return await api.delete(url);
};

const addUsersToGroup = async (users: TUser[]) => {
  let usernameList: string[] = [];
  users.forEach(user => usernameList.push(user.username));
  const url = `/api/access/group/users/`;
  const body = {
    group_name: GROUP_NAME,
    users: usernameList
  };
  return await api.post(url, body);
};

const removeUsersFromGroup = async (users: TUser[]) => {
  let usernameList: string[] = [];
  users.forEach(user => usernameList.push(user.username));
  const url = `/api/access/group/users/`;
  const body = {
    group_name: GROUP_NAME,
    users: usernameList
  };
  return await api.delete(url, { data: body });
};

const changePassword = async (username: string, password: string) => {
  const url = `api/access/user/${username}`;
  const body = {
    password: password
  };
  return await api.patch(url, body);
};

const usersService = {
  getAllUsers,
  getGroupUsers,
  createUser,
  addUsersToGroup,
  removeUsersFromGroup,
  changePassword,
  deleteUser
};

export default usersService;
