import { useMutation, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const groupsService = new GroupsService();

const useCreateGroup = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(groupsService.createGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["groups"],
            });
            addPopup({
                title: "Grupo criado com sucesso.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao criar grupo.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useCreateGroup;
