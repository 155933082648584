import { useQuery } from '@tanstack/react-query';
import RestaurantsService from 'services/restaurantsService';
import { SdrBasedErrors, SdrBasedResponses } from '../utils/Api/responses/responses';
import { AxiosError } from 'axios';

const restaurantsService = new RestaurantsService();

const useRestaurants = (query?: Object, definitions?: Object) =>
  useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
    ...definitions,
    queryKey: ['restaurants', query],
    queryFn: () => restaurantsService.getRestaurantsWithoutDeliveryArea(query),
    staleTime: 60 * 60 * 1000 * 24,
    keepPreviousData: true
  });
export default useRestaurants;
