import { useRef } from 'react';

import { Box, Typography } from '@mui/material';

import Button from 'components/Button';
import ComponentHeader from 'components/ComponentHeader';

import useMutation from 'hooks/useMutate';

import VerifiedLeadsApi from 'services/sdrApi/verifiedLeadsService';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const verifiedLeadsService = new VerifiedLeadsApi();

const VerifiedLeads = () => {
  const uploadFile = useRef<any>();

  const { mutate: sendSpreadSheet, isLoading: sendingSpreadSheet } = useMutation({
    successText: 'Dados inseridos com sucesso.',
    errorText: 'Falha ao inserir dados.',
    queryKeyArray: ['verified-leads'],
    callback: verifiedLeadsService.post
  });

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        const formData = new FormData();
        formData.append('file', files[0]);
        sendSpreadSheet(formData);
      }
    } catch {}
  };

  return (
    <Box>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Leads Verificados' />
        <ComponentHeader.Desc text='Faça upload de planilhas com leads que já foram verificados' />
      </ComponentHeader.Root>
      <Button
        startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
        loading={sendingSpreadSheet}
        onClick={() => {
          uploadFile.current.click();
        }}
      >
        <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
      </Button>
      <input
        style={{ display: 'none' }}
        id='filter-lead'
        type='file'
        ref={uploadFile}
        onChange={event => onFileUpload(event)}
      />
    </Box>
  );
};

export default VerifiedLeads;
