import { useEffect, useRef, useState } from "react";
import { usePopup } from "./usePopup";
import { Token } from "./useAuth";

import { useLocation } from "react-router-dom";

import { SDR_WS } from "../settings";


export const useWebSocket = () => {
    const [isReady, setIsReady] = useState(false);
    const [processingData, setProcessingData] = useState(false);
    const [monthlySearches, setMonthlySearches] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [errorsArray, setErrorsArray] = useState<any[]>([]);

    const { addPopup } = usePopup();
    const location = useLocation();

    const ws = useRef<WebSocket | null>(null);

    const paths = {
        "/filter": "leads/apply-filters",
        "/enrichment": "leads/search-information",
        "/phone-validation": "leads/phone-number-validator",
        "/classify": "leads/classification",
        "/recommend": "sales-funnel/recommendation",
        "/search": "leads/search",
        "/management/leads/": "leads/out-scrapper"
    };

    useEffect(() => {
        let socket: WebSocket;

        const connect = () => {
            const path = (paths as any)[location.pathname];
            socket = new WebSocket(
                `${SDR_WS}/${path}?Authentication=Bearer ${Token()}`
            );

            socket.onopen = () => setIsReady(true);

            socket.onclose = () => {
                setIsReady(false);
                setProcessingData(false);
            };

            socket.onerror = () => {
                addPopup({
                    type: "error",
                    title: "Conexão com WebSocket falhou!",
                    duration: "temporary",
                });
                socket?.close();
            };

            socket.onmessage = (event) => {
                if (event.data) {
                    try {
                        if (event.data instanceof Blob) {
                            setProcessingData(false);
                            const documentToDownload =
                                window.URL.createObjectURL(
                                    new Blob([event.data])
                                );

                            const documentDownloadLink =
                                document.createElement("a");

                            documentDownloadLink.href = documentToDownload;
                            documentDownloadLink.setAttribute(
                                "download",
                                `${new Date()
                                    .toISOString()
                                    .replaceAll(":", "_")}.xlsx`
                            );
                            document.body.appendChild(documentDownloadLink);
                            documentDownloadLink.click();
                            documentDownloadLink.parentNode?.removeChild(
                                documentDownloadLink
                            );
                        } else {
                            const data = JSON.parse(event.data);
                            if (data.error_message) {
                                let message = data.error_message;
                                let date = new Date();
                                let key = "time";
                                let value = `${date.getFullYear()}-${
                                    date.getMonth() + 1
                                }-${date.getDate()}-${date.getHours()}:${date.getMinutes()}`;
                                message[key] = value;
                                setErrorsArray((oldState) => [
                                    message,
                                    ...oldState,
                                ]);
                            }
                            if (data.status === "fail") {
                                addPopup({
                                    type: "error",
                                    title: "O processo foi interrompido por erros.",
                                    description:
                                        "Verifique o log de erros no canto inferior direito.",
                                    duration: "permanent",
                                });
                                setProcessingData(false);
                            }
                            if (data.monthly_searches)
                                setMonthlySearches(data.monthly_searches);
                            if (data.percentage) setPercentage(data.percentage);
                            if (data.step && data.status === "success")
                                setActiveStep(data.step);
                        }
                    } catch {
                        addPopup({
                            type: "error",
                            title: "Falha ao gerar planilha ou receber dados.",
                            duration: "temporary",
                        });
                        ws.current?.close();
                    }
                }
            };

            ws.current = socket;
        };
        connect();

        return () => {
            ws.current?.close();
        };
    }, [addPopup]);

    const send = ws.current?.send.bind(ws.current);

    return {
        isReady,
        send,
        setProcessingData,
        processingData,
        monthlySearches,
        activeStep,
        percentage,
        errorsArray,
    };
};
