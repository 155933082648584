import { useQuery } from "@tanstack/react-query";
import UsersService from "../../services/usersServiceClass";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";
import { IUser } from "../../interfaces/IUser";

const usersService = new UsersService();

const useUsers = () =>
    useQuery<IUser[], PermissionsApiErros>({
        queryKey: ["users"],
        queryFn: () => usersService.getAllUsers(),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar usuários da Frexco.",
        },
    });
export default useUsers;
