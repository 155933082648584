import { useMutation, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const groupsService = new GroupsService();

const useAddPermsToGroup = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(groupsService.addPermsToGroup, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: ["group-perms", variables.group],
            });
            addPopup({
                title: "Permissões adicionadas ao grupo.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao adicionar permissões ao grupo.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useAddPermsToGroup;
