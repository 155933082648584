import { useQuery } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";
import { IUser } from "../../interfaces/IUser";

const groupsService = new GroupsService();

const useGroupUsers = (group: string) =>
    useQuery<IUser[], PermissionsApiErros>({
        queryKey: ["group-users", group],
        queryFn: () => groupsService.getGroupUsers(group),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: `Erro ao buscar usuários do grupo ${group}.`,
        },
    });
export default useGroupUsers;
