import { useMutation, useQueryClient } from "@tanstack/react-query";
import UsersService from "../../services/usersServiceClass";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const usersService = new UsersService();

const useDeleteUser = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(usersService.deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey[0] === "group-users" ||
                    query.queryKey[0] === "users",
            });
            addPopup({
                title: "Usuário deletado com sucesso.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao deletar usuário.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useDeleteUser;
