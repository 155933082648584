import { useState } from "react";

import { Box, Typography } from "@mui/material";

import Button from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import DataGrid from "../../../../../components/DataGrid";

import useDeleteGroup from "../../../../../hooks/AcessApi/useDeleteGroup";
import { HardCodedGroups } from "../../../../../mock/templateData";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

interface IGroup {
    id: number;
    name: string;
}

export const DeleteGroupList = () => {
    const [openConfirmDeleteGroupDialog, setOpenConfirmDeleteGroupDialog] =
        useState(false);

    const { mutate: deleteGroup, isLoading: deletingGroup } = useDeleteGroup();

    const GroupsGridRef = useGridApiRef();
    const [groupsGridColumnDefs] = useState<GridColDef<IGroup>[]>([
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const handleDeleteGroup = () => {
        const selectedGroups = GroupsGridRef.current.getSelectedRows();
        const selectGroupsArray = Array.from(
            selectedGroups.values()
        ) as IGroup[];
        const names = selectGroupsArray
            ?.map((group) => group.name)
            .filter((group) => typeof group === "string") as string[];
        deleteGroup(names[0]);
    };

    return (
        <Box>
            <Typography sx={{ marginBottom: "5px" }} variant="h4">
                Excluir Grupo
            </Typography>
            <Box width={"100%"} height={"300px"}>
                <DataGrid
                    apiRef={GroupsGridRef}
                    columns={groupsGridColumnDefs}
                    rows={HardCodedGroups}
                    isRowSelectable={() => true}
                    checkboxSelection
                />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <Button
                    onClick={() => setOpenConfirmDeleteGroupDialog(true)}
                    loading={deletingGroup}
                >
                    <Typography variant="body2">Deletar</Typography>
                </Button>
            </Box>
            {openConfirmDeleteGroupDialog && (
                <ConfirmationDialog
                    title="Deseja mesmo deletar o grupo?"
                    message="Caso confirme todos os usuários participantes perderão os privilégios concedidos por este grupo."
                    openDialog={openConfirmDeleteGroupDialog}
                    setOpenDialog={setOpenConfirmDeleteGroupDialog}
                    callback={handleDeleteGroup}
                />
            )}
        </Box>
    );
};
