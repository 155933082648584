import { createContext, useCallback, useContext, useState } from "react";

import { PopupContainer } from "../components/popup/popupContainer";

import { TPopupMessage } from "../@types/popup";

import {v4} from "uuid";
import { TPopupContext } from "../@types/popup";

const PopupContext = createContext({} as TPopupContext);

interface IPopupProvider {
  children: React.ReactNode;
}

interface IAddPopup {
  type: string;
  title: string;
  description?: string;
  duration: "temporary" | "permanent";
}

const PopupProvider = ({ children }: IPopupProvider) => {

  const [messages, setMessages] = useState<TPopupMessage[]>([]);
  const [duration, setDuration] = useState("");

  const addPopup = useCallback(({ type, title, description, duration }: IAddPopup) => {
    const id = v4();

    const popup:TPopupMessage = {
      id,
      type,
      title,
      description,
    };
    
    setDuration(duration);
    setMessages((state) => [...state, popup]);
  }, []);

  const removePopup = (id: string) => {
    setMessages((state) => state.filter((message) => id !== message.id));
  };

  return (
    <PopupContext.Provider value={{ addPopup, removePopup }}>
      {children}
      <PopupContainer duration={duration} messages={messages} />
    </PopupContext.Provider>
  );
};

const usePopup = () => {
  const context = useContext(PopupContext);
  return context;
};

export { PopupProvider, usePopup };
