import { ReactNode, useState } from "react";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";

import CreateGroupForm from "../CreateGroupForm";
import { DeleteGroupList } from "../DeleteGroupList";

import theme from "../../../../../styles/theme";

interface ICreateUserDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IButtonInfo {
    label: string;
    stateKey: string;
    icon: ReactNode;
}

export const ManageGroupsDialog = ({
    openDialog,
    setOpenDialog,
}: ICreateUserDialog) => {
    const [activeButton, setActiveButtonIndex] = useState<IButtonInfo | null>({
        label: "Criar Grupo",
        stateKey: "showCreateGroupForm",
        icon: <GroupAddOutlinedIcon />,
    });

    const buttons: IButtonInfo[] = [
        {
            label: "Criar Grupo",
            stateKey: "showCreateGroupForm",
            icon: <GroupAddOutlinedIcon />,
        },
        {
            label: "Excluir Grupo",
            stateKey: "showDeleteGroupList",
            icon: <GroupRemoveOutlinedIcon />,
        },
    ];

    const handleButtonClick = (index: number) => {
        setActiveButtonIndex(buttons[index]);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "none",
                },
            }}
            open={openDialog}
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    padding: "0",
                    minWidth: "400px",
                }}
            >
                <Typography
                    sx={{
                        textAlign: "center",
                        padding: "16px 24px 0px 24px ",
                    }}
                    variant="subtitle2"
                >
                    Gerenciar Grupos
                </Typography>
            </DialogTitle>
            <DialogActions
                sx={{
                    padding: "0px 8px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    paddingBottom: "5px",
                    borderBottom: `.5px solid ${theme.palette.secondary.light}`,
                }}
            >
                {buttons.map((button, index) => (
                    <Tooltip title={button.label}>
                        <IconButton
                            key={index}
                            onClick={() => handleButtonClick(index)}
                            sx={{
                                color: theme.palette.primary.main,
                                backgroundColor:
                                    activeButton?.stateKey === button.stateKey
                                        ? "rgba(0, 0, 0, 0.04)"
                                        : "none",
                            }}
                            size="large"
                        >
                            {button.icon}
                        </IconButton>
                    </Tooltip>
                ))}
            </DialogActions>
            <DialogContent>
                {activeButton?.stateKey === "showCreateGroupForm" && (
                    <CreateGroupForm />
                )}
                {activeButton?.stateKey === "showDeleteGroupList" && (
                    <DeleteGroupList />
                )}
            </DialogContent>
        </Dialog>
    );
};
