import React, { useRef, useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useWebSocket } from '../../../../../hooks/useWebSocket';
import { useSpreadSheet } from '../../../../../hooks/useSpreadSheet';
import { TemplateData } from '../../../../../mock/templateData';

import Button from '../../../../../components/Button';
import InfoCard from '../../../../../components/InfoCard';
import { Stepper } from '../../../../../components/Stepper';
import { ErrorsDialog } from '../../../../../components/ErrorsDialog';
import FloatingButton from '../../../../../components/FloatingButton';

import './styles.css';
import ComponentHeader from 'components/ComponentHeader';

const ClassifyLeads = () => {
  const uploadFile = useRef<any>();
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const steps = ['Filtragem', 'Enriquecimento', 'Localização', 'Validação'];

  const { isReady, send, setProcessingData, processingData, activeStep, percentage, errorsArray } = useWebSocket();

  const { exportToExcel, creatingExcel } = useSpreadSheet();

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };

      reader.onloadend = () => {};
    } catch {}
  };

  const onDownloadClick = () => {
    exportToExcel(TemplateData, 'Classificação de leads - exemplo de entrada');
  };

  return (
    <div>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Classificação de leads' />
        <ComponentHeader.Desc
          text='Classifica os leads passando a planilha de dados por todas as etapas (Filtragem, Enriquecimento e Validação de
          número).'
        />
      </ComponentHeader.Root>
      <Box className={'contentContainer'}>
        <Box className={'infoContainer'}>
          <InfoCard
            icon={
              isReady ? (
                <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
              ) : (
                <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
              )
            }
            title='Status de Conexão'
            value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
          />
          <Stepper activeStep={activeStep} steps={steps} />
          <InfoCard
            icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Progresso'
            value={`${percentage.toFixed(2).toString() || 0} %`}
          />
          <Box className={'buttonsContainer'}>
            <Button
              onClick={onDownloadClick}
              loading={creatingExcel}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              <Typography variant='body2'>EXEMPLO DE ENTRADA</Typography>
            </Button>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={processingData}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
              disabled={!isReady}
            >
              <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
            </Button>
          </Box>
          <input
            style={{ display: 'none' }}
            id='filter-lead'
            type='file'
            ref={uploadFile}
            onChange={event => onFileUpload(event)}
          />
        </Box>
        {errorsArray.length > 0 && (
          <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
            <InfoOutlinedIcon />
          </FloatingButton>
        )}
      </Box>
      <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
    </div>
  );
};

export default ClassifyLeads;
