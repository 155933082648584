import { RouteProps } from 'react-router-dom';

import Login from '../pages/public/Login';
import Filter from '../pages/private/Filter';
import Classify from '../pages/private/Classify';
import Welcome from '../pages/private/Welcome';
import Enrichment from '../pages/private/Enrichment';
import PhoneValidation from '../pages/private/PhoneValidation';
import Search from '../pages/private/Search';
import LeadManagement from '../pages/private/LeadManagement';
import AdminPanel from '../pages/private/AdminPanel';
import Profile from '../pages/private/Profile';
import RdstationBulkModificationPage from 'pages/private/RdstationBulkModification';
import DeliveryRegion from 'pages/private/DeliveryRegion';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PlaceIcon from '@mui/icons-material/Place';

export type IRoute = RouteProps & {
  isPrivate: boolean;
  requiredPermissions: string[];
  menuName?: string;
  menuIcon?: React.ReactNode;
  path: string;
};

const RouteList: IRoute[] = [
  {
    isPrivate: false,
    requiredPermissions: [],
    path: '/login',
    element: <Login />
  },
  {
    isPrivate: true,
    requiredPermissions: [],
    path: '/',
    element: <Welcome />
  },
  {
    isPrivate: true,
    requiredPermissions: ['leads | apply-filters'],
    path: '/filter',
    element: <Filter />,
    menuName: 'Filtragem',
    menuIcon: <FilterAltIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['leads | search-information'],
    path: '/enrichment',
    element: <Enrichment />,
    menuName: 'Enriquecimento',
    menuIcon: <ScreenSearchDesktopIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['leads | phone-number-validator'],
    path: '/phone-validation',
    element: <PhoneValidation />,
    menuName: 'Validação',
    menuIcon: <PhoneIphoneIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['leads | classification'],
    path: '/classify',
    element: <Classify />,
    menuName: 'Classificação',
    menuIcon: <EmojiEventsIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['tamarindo | admin'],
    path: '/admin',
    element: <AdminPanel />
  },
  {
    isPrivate: true,
    requiredPermissions: ['leads | search'],
    path: '/search',
    element: <Search />,
    menuName: 'Busca de Leads',
    menuIcon: <TravelExploreIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['management | leads'],
    path: '/management/leads/',
    element: <LeadManagement />,
    menuName: 'Gerenciamento de Leads',
    menuIcon: <ManageAccountsOutlinedIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['tamarindo | user'],
    path: '/profile',
    element: <Profile />
  },
  {
    isPrivate: true,
    requiredPermissions: ['rdstation | bulk-modification'],
    path: '/rdstation/bulk-modification',
    element: <RdstationBulkModificationPage />,
    menuName: 'Modificação em Massa',
    menuIcon: <FactCheckOutlinedIcon />
  },
  {
    isPrivate: true,
    requiredPermissions: ['area'],
    path: '/delivery-region',
    element: <DeliveryRegion />,
    menuName: 'Áreas de Entrega',
    menuIcon: <PlaceIcon />
  }
];

export default RouteList;
