import {
  Box,
  Card,
  CardContent,
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Connector } from "./connector";
import { StepIcon } from "./stepIcon";

interface IStepper {
  activeStep: number;
  steps: string[];
}

export const Stepper = ({ activeStep, steps }: IStepper) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <Card
      sx={{
        "@media (max-width: 620px)": {
          width: "fit-content",
        },
      }}
    >
      <CardContent>
        <Box>
          <Typography gutterBottom variant="overline" color="textSecondary">
            PROGRESSO
          </Typography>

          <MuiStepper
            alternativeLabel={width < breakpoint ? false : true}
            orientation={width < breakpoint ? "vertical" : "horizontal"}
            activeStep={activeStep}
            connector={<Connector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </MuiStepper>
        </Box>
      </CardContent>
    </Card>
  );
};
