import { useMutation, useQueryClient } from "@tanstack/react-query";
import PermissionsService from "../../services/permissionServiceClass";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const permissionsService = new PermissionsService();

const useDeletePerm = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(permissionsService.deletePerm, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey[0] === "group-perms" ||
                    query.queryKey[0] === "user-perms",
            });
            addPopup({
                title: "Permissão deletada com sucesso.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao deletar permissão.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useDeletePerm;
