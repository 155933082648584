function whatState() {
    if (
        window.location.href.includes("localhost") ||
        window.location.href.includes("stg")
    ) {
        return {
            SDR_WS: "wss://stg.ms.frexco.com.br/api/sdr",
            MS_API: "https://stg.ms.frexco.com.br",
        };
    }
    return {
        SDR_WS: "wss://ms.frexco.com.br/api/sdr",
        MS_API: "https://ms.frexco.com.br",
    };
}

const { MS_API, SDR_WS } = whatState();
export { MS_API, SDR_WS };
