import { Box, List, Typography, useMediaQuery } from "@mui/material";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { DrawerListItems } from "../components/NavListItems";

import logo from "../assets/images/logo.png";
import configLayout from "./ConfigLayout";

const { APPBAR, DRAWER_WIDTH } = configLayout;

const Drawer = styled(MuiDrawer)(({ theme, open, variant }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    height: `calc(100vh - ${APPBAR}px)`,
    marginTop: APPBAR,
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      height: "100vh",
      marginTop: 0,
    },
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "0px",
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9),
      },
    }),
    "&:hover": {
      width: DRAWER_WIDTH,
    },
  },
}));

interface ISideBar extends DrawerProps {
  openDrawer: boolean;
}

const SideBar = ({ openDrawer, onClose }: ISideBar) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  if (isDesktop) {
    return (
      <Drawer variant="permanent" open={openDrawer} onClose={onClose}>
        <List component="nav">
          <DrawerListItems />
        </List>
      </Drawer>
    );
  }

  return (
    <Drawer variant="temporary" open={openDrawer} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: `${APPBAR}px`,
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        }}
      >
        <img
          src={logo}
          alt="logomarca da empresa"
          style={{ width: "2.50rem", height: "2.50rem" }}
        />
        <Typography variant="subtitle2" color="secondary.light" noWrap>
          Tamarindo
        </Typography>
        <Typography variant="subtitle2" color="primary.main" noWrap>
          .Frexco
        </Typography>
      </Box>
      <List component="nav">
        <DrawerListItems toggleDrawer={onClose} />
      </List>
    </Drawer>
  );
};

export default SideBar;
