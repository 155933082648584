import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../apiClient";
import { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";

export const ApiInterceptor = ({ children }: { children: any }) => {
    const navigate = useNavigate();
    const { signOut } = useAuth();

    useEffect(() => {
        api.interceptors.request.use(
            (response: AxiosRequestConfig<any>) => {
                return response;
            },
            () => {
                throw new Error(
                    "Erro desconhecido.\nContate um administrador."
                );
            }
        );
        api.interceptors.response.use(
            (response: AxiosResponse<any>) => {
                return response;
            },
            (error: AxiosError<any>) => {
                if (error.response?.status === 401) {
                    if (typeof window !== undefined) {
                        signOut();
                        navigate("/login");
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }, [navigate, signOut]);
    return children;
};
