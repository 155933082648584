import { Token } from "../hooks/useAuth";
import { api } from "../utils/Api/apiClient";

class AreaManagementService {
    url: string;
    constructor() {
        this.url = `api/sdr/v1/management/areas/`;
    }

    getAreas = (params?: Object) =>
        api
            .get(this.url, {
                headers: {
                    Authorization: `Bearer ${Token()}`,
                },
                params: params,
            })
            .then((res) => res.data);

    getAreasLeadsInfo = (params?: Object) =>
        api
            .get(this.url + "leads/", {
                headers: {
                    Authorization: `Bearer ${Token()}`,
                },
                params: params,
            })
            .then((res) => res.data);

    attAreasPriority = (data?: Object) =>
        api
            .patch(this.url, data, {
                headers: {
                    Authorization: `Bearer ${Token()}`,
                },
            })
            .then((res) => res.data);

    searchAreas = () =>
        api
            .post(this.url, {
                headers: {
                    Authorization: `Bearer ${Token()}`,
                },
            })
            .then((res) => res.data);
}

export default AreaManagementService;
