import { useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import TextField from '../../../components/TextField/index';
import Button from '../../../components/Button/index';
import ServiceTitle from '../../../components/ServiceTitle';
import InfoCard from '../../../components/InfoCard';
import FloatingButton from '../../../components/FloatingButton';
import { ErrorsDialog } from '../../../components/ErrorsDialog';

import { useWebSocket } from '../../../hooks/useWebSocket';

import './styles.css';
import ComponentHeader from 'components/ComponentHeader';

const formSchema = yup
  .object()
  .shape({
    lat: yup.string().required('A latitude é um campo obrigatório').trim(),
    lng: yup.string().required('A longitude é um campo obrigatório').trim(),
    radius: yup.string().required('O raio é um campo obrigatório').trim(),
    types: yup.array().required('O campo de tipos é obrigatório').nullable().max(3, 'Serão aceitos no máximo 3 tipos')
  })
  .required();

type FormData = yup.InferType<typeof formSchema>;

const Search = () => {
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const { send, isReady, monthlySearches, percentage, errorsArray } = useWebSocket();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm<FormData>({
    resolver: yupResolver(formSchema)
  });

  const onSubmit = (data: FormData) => {
    send?.(JSON.stringify(data));
  };

  return (
    <div>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Busca de Leads' />
        <ComponentHeader.Desc text='Busca leads no serviço Google Places baseado na latitude, longitude e raio no mapa.' />
      </ComponentHeader.Root>
      <Box className={'contentContainer'}>
        <Box className={'infoContainer'}>
          <InfoCard
            icon={
              isReady ? (
                <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
              ) : (
                <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
              )
            }
            title='Status de Conexão'
            value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
          />

          <InfoCard
            icon={<SupervisedUserCircleIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Pesquisas mensais'
            value={monthlySearches.toString()}
          />

          <InfoCard
            icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Progresso'
            value={`${percentage.toFixed(2).toString() || 0} %`}
          />
        </Box>

        <Box className={'contentContainer'}>
          <Typography variant='h6'>Parâmetros de busca</Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant='outlined'
              label='Latitude *'
              type='text'
              {...register('lat')}
              error={!!errors?.lat}
              helperText={<>{errors.lat?.message}</>}
            />

            <TextField
              variant='outlined'
              label='Longitude *'
              type='text'
              {...register('lng')}
              error={!!errors?.lng}
              helperText={<>{errors.lng?.message}</>}
            />

            <TextField
              variant='outlined'
              label='Raio *'
              type='text'
              {...register('radius')}
              error={!!errors?.radius}
              helperText={<>{errors.radius?.message}</>}
            />

            <Autocomplete
              multiple
              freeSolo
              options={[]}
              defaultValue={[]}
              {...register('types')}
              onChange={(event, value, reason) => {
                setValue('types', value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Tipos *'
                  helperText={<>{errors.types?.message}</>}
                  className={'inputTextField'}
                  error={!!errors?.types}
                />
              )}
            />

            <Box>
              <Button disabled={!isReady} className={'buttonBox'} type='submit'>
                <Typography variant='h4'>Enviar</Typography>
              </Button>
            </Box>
          </form>
        </Box>
        {errorsArray.length > 0 && (
          <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
            <InfoOutlinedIcon />
          </FloatingButton>
        )}
      </Box>
      <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
    </div>
  );
};

export default Search;
