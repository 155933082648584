import { useMutation, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { usePopup } from "../usePopup";

const groupsService = new GroupsService();

const useGroupRemovePerms = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(groupsService.removePermsFromGroup, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(["group-perms", variables.group]);
            addPopup({
                title: `Permissões removidas do grupo ${variables.group}.`,
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error, variables) => {
            addPopup({
                title: `Erro ao remover permissões do grupo ${variables.group}`,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useGroupRemovePerms;
