import { useRef, useState } from 'react';
import { useChangeLeadsUsers } from '../../../../../hooks/useChangeLeadsUsers';

import { Box, Typography } from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import PendingIcon from '@mui/icons-material/Pending';

import Button from '../../../../../components/Button';
import FloatingButton from '../../../../../components/FloatingButton';
import InfoCard from '../../../../../components/InfoCard';
import RecordsWithError from './components/RecordsWithError';
import CorrectRecords from './components/CorrectRecords';
import ConfirmationForm from './components/ConfirmationForm';

import { ErrorsDialog } from '../ErrorsDialog';

import './styles.css';
import ComponentHeader from 'components/ComponentHeader';

const RdstationBulkModification = () => {
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const uploadFile = useRef<any>();
  const {
    isReady,
    send,
    setProcessingData,
    processingData,
    errorsArray,
    rowsToChange,
    rowsToIgnore,
    canConfirmExecution,
    percentage
  } = useChangeLeadsUsers();

  const onFileUploadViaWebSocket = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };
    } catch {}
  };

  return (
    <>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Modificação em massa no Rdstation' />
        <ComponentHeader.Desc
          text='Com o Modificador em Massa do Tamarindo, você pode facilmente
                efetuar várias modificações no RD Station, simplificando o
                processo. Basta fornecer os IDs das empresas que constam na
                tabela Lead Information envolvidas(empresas sem IDs não podem
                ser modificadas) para mover negociações e criar novas tarefas,
                selecionando o hunter responsável e especificando o estágio do
                funil. Lembre-se de carregar a planilha corretamente e escolher
                o tipo de tarefa desejado para uma gestão mais eficaz das
                oportunidades no RD Station. Após enviar a planilha você deverá
                verificar os registros que estão com algum tipo de erro(caso
                existam) e os registros que sofrerão as alterações nas tabelas a
                baixo, após isso poderá selecionar o título desejado para as
                tarefas que serão criadas e confirmar a execução.'
        />
      </ComponentHeader.Root>
      <Box>
        <Box display={'flex'} gap={'20px'} alignItems={'center'} marginBottom={'10px'}>
          <InfoCard
            icon={
              isReady ? (
                <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
              ) : (
                <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
              )
            }
            title='Status de Conexão'
            value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
          />
          <InfoCard
            icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Progresso'
            value={`${percentage.toFixed(2).toString() || 0} %`}
          />
        </Box>
        <Box className={'buttonsContainer'}>
          <Button
            startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
            loading={processingData}
            loadingPosition='start'
            onClick={() => {
              uploadFile.current.click();
            }}
            disabled={!isReady}
          >
            <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
          </Button>
        </Box>
        <Box>
          <Box display={'flex'} gap={'30px'}>
            <RecordsWithError recordsWithError={rowsToIgnore} />
            <CorrectRecords correctRecords={rowsToChange} />
          </Box>
          <Box display={'flex'} justifyContent={'end'}>
            <ConfirmationForm
              send={send}
              canConfirmExecution={canConfirmExecution}
              setProcessingData={setProcessingData}
            />
          </Box>
        </Box>
        <input
          style={{ display: 'none' }}
          id='filter-lead'
          type='file'
          ref={uploadFile}
          onChange={event => onFileUploadViaWebSocket(event)}
        />
        {errorsArray.length > 0 && (
          <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
            <InfoOutlinedIcon />
          </FloatingButton>
        )}
        <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
      </Box>
    </>
  );
};

export default RdstationBulkModification;
