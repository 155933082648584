import { useRef, useState } from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Button from '../../../components/Button';
import InfoCard from '../../../components/InfoCard';
import ServiceTitle from '../../../components/ServiceTitle';
import FloatingButton from '../../../components/FloatingButton';
import { ErrorsDialog } from '../../../components/ErrorsDialog';

import { useWebSocket } from '../../../hooks/useWebSocket';
import { useSpreadSheet } from '../../../hooks/useSpreadSheet';

import { TemplateDataPhoneValidation } from '../../../mock/templateData';
import ComponentHeader from 'components/ComponentHeader';

const PhoneValidation = () => {
  const uploadFile = useRef<any>();
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);
  const [action, setAction] = useState(null);

  const { isReady, send, setProcessingData, processingData, percentage, errorsArray } = useWebSocket();

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };

      reader.onloadend = () => {};
    } catch {}
  };

  const { exportToExcel, creatingExcel } = useSpreadSheet();

  const onDownloadClick = () => {
    exportToExcel(TemplateDataPhoneValidation, 'Validação de números - exemplo de entrada');
  };

  const handleChange = (event: any) => {
    setAction(event.target.value);
    send?.(event.target.value);
  };

  return (
    <>
      <div>
        <ComponentHeader.Root>
          <ComponentHeader.Title text='Validação de número' />
          <ComponentHeader.Desc text='Valida os números de telefone dos leads utilizando o serviço de checagem.' />
        </ComponentHeader.Root>

        <Box className={'contentContainer'}>
          <Box className={'infoContainer'}>
            <InfoCard
              icon={
                isReady ? (
                  <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
                ) : (
                  <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
                )
              }
              title='Status de Conexão'
              value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
            />

            <InfoCard
              icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
              title='Progresso'
              value={`${percentage.toFixed(2).toString() || 0} %`}
            />

            <Box className={'buttonsContainer'}>
              <Button
                loading={creatingExcel}
                onClick={onDownloadClick}
                startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
              >
                <Typography variant='body2'>EXEMPLO DE ENTRADA</Typography>
              </Button>
              <Button
                startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
                loading={processingData}
                loadingPosition='start'
                onClick={() => {
                  uploadFile.current.click();
                }}
                disabled={!isReady || !action ? true : false}
              >
                <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
              </Button>

              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='demo-select-small-label'>Tipo de validação</InputLabel>
                <Select
                  style={{ width: 250 }}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={action}
                  label='Tipo de validação'
                  onChange={handleChange}
                  disabled={!isReady ? true : false}
                >
                  <MenuItem value='complete'>Completa</MenuItem>
                  <MenuItem value='whatsapp'>Whatsapp</MenuItem>
                  <MenuItem value='dial'>Ligações</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <input
              style={{ display: 'none' }}
              id='filter-lead'
              type='file'
              ref={uploadFile}
              onChange={event => onFileUpload(event)}
            />
          </Box>
          {errorsArray.length > 0 && (
            <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
              <InfoOutlinedIcon />
            </FloatingButton>
          )}
        </Box>
        <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
      </div>
    </>
  );
};

export default PhoneValidation;
