import PageContainer from 'components/PageContainer';
import ClassifyLeads from './components/ClassifyLeads';
import VerifiedLeads from './components/VerifiedLeads';

const Classify = () => {
  return (
    <PageContainer>
      <ClassifyLeads />
      <VerifiedLeads />
    </PageContainer>
  );
};

export default Classify;
