import RdstationBulkModification from './components/RdstationBulkModification';
import PageContainer from 'components/PageContainer';
import ComponentHeader from 'components/ComponentHeader';

const RdstationBulkModificationPage = () => {
  return (
    <PageContainer>
      <RdstationBulkModification />
    </PageContainer>
  );
};

export default RdstationBulkModificationPage;
