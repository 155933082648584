import { useState } from 'react';

import { Accordion, AccordionDetails, Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import AccordionSummary from '../../../../../components/AccordionSummary';

import useGroupUsers from '../../../../../hooks/AcessApi/useGroupUsers';
import UserListComponent from './UserListComponent';
import { AddUserToGroupDialog } from '../../../../../components/AddUserToGroup';
import { ManageGroupDialog } from '../ManageGroupDialog';

interface IAccordionItem {
  group: string;
}

const AccordionItem = ({ group }: IAccordionItem) => {
  const [openAddUserToGroupDialog, setOpenAddUserToGroupDialog] = useState(false);
  const [openManageGroupDialog, setOpenManageGroupDialog] = useState(false);

  const { data: groupUsers } = useGroupUsers(group);

  const handleOpenAddUserToGroupDialog = () => {
    setOpenAddUserToGroupDialog(true);
  };

  const handleOpenManageGroupDialog = () => {
    setOpenManageGroupDialog(true);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          key={group}
          expandIcon={
            <Tooltip title='Usuários'>
              <ExpandMoreIcon />
            </Tooltip>
          }
          aria-controls='panel1a-content'
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant='h3'>{group}</Typography>
            <Box>
              <Tooltip title='Adicionar Usuário(s)'>
                <IconButton onClick={handleOpenAddUserToGroupDialog}>
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Gerenciar grupo'>
                <IconButton onClick={handleOpenManageGroupDialog}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ margin: '0 auto' }}>
          {groupUsers?.map((user, index) => (
            <>
              <UserListComponent key={index} user={user} group={group} />
              <Divider />
            </>
          ))}
        </AccordionDetails>
      </Accordion>
      {openAddUserToGroupDialog && (
        <AddUserToGroupDialog
          group={group}
          openDialog={openAddUserToGroupDialog}
          setOpenDialog={setOpenAddUserToGroupDialog}
        />
      )}
      {openManageGroupDialog && (
        <ManageGroupDialog group={group} openDialog={openManageGroupDialog} setOpenDialog={setOpenManageGroupDialog} />
      )}
    </>
  );
};

export default AccordionItem;
