import { useState, useRef, useEffect } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import { usePopup } from '../../../hooks/usePopup';
import { useWebSocket } from '../../../hooks/useWebSocket';

import Button from '../../../components/Button';
import ServiceTitle from '../../../components/ServiceTitle';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import leadManagementService from '../../../services/leadManagementService';

import { IRegionsInfo } from '../../../interfaces/IRegion';

import { AxiosError } from 'axios';
import useAreasLeadInfo from '../../../hooks/useAreasLeadInfo';

import './styles.css';
import DataGrid from 'components/DataGrid';
import { GridColDef } from '@mui/x-data-grid';

const LeadManagement = () => {
  const { addPopup } = usePopup();

  const uploadFile = useRef<any>();

  const { isReady, send, setProcessingData, processingData } = useWebSocket();

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };

      reader.onloadend = () => {};
    } catch {}
  };

  const [sendingSSheet, setSSendingSheet] = useState(false);
  const [regionsInfoColumnDefs] = useState<GridColDef<IRegionsInfo>[]>([
    {
      field: 'area_name',
      minWidth: 150,
      headerName: 'Nome',
      flex: 1,
      renderCell: params => {
        return params.row.area_name ? params.row.area_name : 'Não validado';
      }
    },
    {
      field: 'leads_quantity',
      minWidth: 80,
      flex: 1,
      headerName: 'Leads'
    },
    {
      field: 'leads_with_whatsapp_quantity',
      minWidth: 100,
      flex: 1,
      headerName: 'Whatsapp'
    },
    {
      field: 'leads_answer_calls_quantity',
      minWidth: 100,
      flex: 1,
      headerName: 'Atende Chamadas'
    }
  ]);

  const {
    data: getAreasLeadInfoResponse,
    isFetching: gettingAreasLeadInfo,
    error: gettingAreasLeadInfoError
  } = useAreasLeadInfo({ per_page: 100 });

  useEffect(() => {
    if (gettingAreasLeadInfoError) {
      addPopup({
        title: 'Falha ao buscar informação das areas.',
        description: gettingAreasLeadInfoError.response?.data.error,
        type: 'error',
        duration: 'temporary'
      });
    }
  }, [gettingAreasLeadInfoError, addPopup]);

  const onMarkingLeadsFileUpload = async (event: React.ChangeEvent) => {
    setSSendingSheet(true);

    const files = (event.target as HTMLInputElement).files;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);

      await leadManagementService
        .sendLeadsSpreadSheet(formData)
        .then(() => {
          addPopup({
            title: 'Planilha enviada!',
            type: 'success',
            duration: 'temporary'
          });
        })
        .catch(
          async (
            err: AxiosError<{
              error: string;
            }>
          ) => {
            addPopup({
              title: 'Falha ao processar planilha.',
              description: err.response?.data.error,
              type: 'error',
              duration: 'temporary'
            });
          }
        );
    }

    setSSendingSheet(false);
  };

  const onUnmarkingLeadsFileUpload = async (event: React.ChangeEvent) => {
    setSSendingSheet(true);

    const files = (event.target as HTMLInputElement).files;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);

      await leadManagementService
        .attLeadsSpreadSheet(formData)
        .then(() => {
          addPopup({
            title: 'Planilha enviada!',
            type: 'success',
            duration: 'temporary'
          });
        })
        .catch(
          async (
            err: AxiosError<{
              error: string;
            }>
          ) => {
            addPopup({
              title: 'Falha ao processar planilha.',
              description: err.response?.data.error,
              type: 'error',
              duration: 'temporary'
            });
          }
        );
    }

    setSSendingSheet(false);
  };

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <ServiceTitle title='Gerenciamento de Leads' />
        <Box sx={{ marginTop: '40px' }} className={'buttonsContainer'}>
          <>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={sendingSSheet}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
            >
              <Typography variant='body2'>PLANILHA LEADS UTILIZADOS</Typography>
            </Button>
            <input
              style={{ display: 'none' }}
              id='filter-lead'
              type='file'
              ref={uploadFile}
              onChange={event => onMarkingLeadsFileUpload(event)}
            />
          </>

          <>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={sendingSSheet}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
            >
              <Typography variant='body2'>PLANILHA LEADS REUTILIZAR</Typography>
            </Button>
            <input
              style={{ display: 'none' }}
              id='filter-lead'
              type='file'
              ref={uploadFile}
              onChange={event => onUnmarkingLeadsFileUpload(event)}
            />
          </>

          <>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={processingData}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
            >
              <Typography variant='body2'>PLANILHA LEADS OUTSCRAPPER</Typography>
            </Button>
            <input
              style={{ display: 'none' }}
              id='filter-lead'
              type='file'
              ref={uploadFile}
              onChange={event => onFileUpload(event)}
            />
          </>
        </Box>
        <Box marginTop={'40px'} width={'100%'} height={'500px'}>
          <DataGrid<IRegionsInfo>
            columns={regionsInfoColumnDefs}
            rows={getAreasLeadInfoResponse?.results ? getAreasLeadInfoResponse?.results : []}
            loading={gettingAreasLeadInfo}
            getRowId={row => row.area_name}
          />
        </Box>
      </div>
    </>
  );
};

export default LeadManagement;
