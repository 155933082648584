import { Autocomplete, Box, Typography } from "@mui/material";
import TextField from "../../../../../../../components/TextField";
import Button from "../../../../../../../components/Button";
import { FieldValues, useForm } from "react-hook-form";
import { HardCodedTaskTitles } from "../../../../../../../mock/templateData";

interface IConfirmationForm {
    send:
        | ((data: string | ArrayBufferLike | Blob | ArrayBufferView) => void)
        | undefined;
    canConfirmExecution: boolean;
    setProcessingData: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationForm = ({
    send,
    canConfirmExecution,
    setProcessingData,
}: IConfirmationForm) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const handleSubmitForm = (formData: FieldValues) => {
        setProcessingData(true);
        send?.(
            JSON.stringify({
                stage: "confirmation",
                taskTitle: formData.task_title,
            })
        );
    };

    return (
        <form
            style={{
                width: "30%",
            }}
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                width={"100%"}
            >
                <Autocomplete
                    sx={{
                        width: "100%",
                    }}
                    id="tags-standard"
                    options={HardCodedTaskTitles}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Título da tarefa"
                            placeholder="Título"
                            {...register("task_title", {
                                required: true,
                            })}
                            error={!!errors?.lost_reason}
                            helperText={
                                errors?.lost_reason?.message ? (
                                    <>{errors?.lost_reason?.message}</>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    )}
                />
                <Button disabled={!canConfirmExecution} type="submit">
                    <Typography variant="body2">CONFIRMAR</Typography>
                </Button>
            </Box>
        </form>
    );
};

export default ConfirmationForm;
