import { useState } from "react";
import { usePopup } from "./usePopup";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const useSpreadSheet = () => {
  const [creatingExcel, setCreatingExcel] = useState(false);
  const { addPopup } = usePopup();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async (excelData: any[], fileName: string) => {
    try {
      setCreatingExcel(true);
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch {
      addPopup({ type: "error", title: "erro ao criar planilha de exemplo.", duration: "permanent", });
    } finally {
      setCreatingExcel(false);
    }
  };

  return {exportToExcel, creatingExcel};
};
