import { AccordionSummary as MuiAccordionSummary } from "@mui/material";
import { styled } from "@mui/material";

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));

export default AccordionSummary;
