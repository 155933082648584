import { Box } from "@mui/material";
import { styled } from "@mui/material";

const PageContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "60px",
    marginBottom: "40px",
    maxWidth: "1440px",
}));

export default PageContainer;
