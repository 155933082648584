import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";
import DataGrid from "../../../../../components/DataGrid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useCreatePerm from "../../../../../hooks/AcessApi/useCreatePerm";
import useDeletePerm from "../../../../../hooks/AcessApi/useDeletePerm";
import useAddPermsToGroup from "../../../../../hooks/AcessApi/useAddPermsToGroup";
import useGroupPerms from "../../../../../hooks/AcessApi/useGroupPerms";

import { IPermissions } from "../../../../../interfaces/IPermissions";

let formSchema = yup.object().shape({
    name: yup.string().required("Nome da permissão é obrigatório"),
});

interface ICreatePermissionDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreatePermissionDialog = ({
    openDialog,
    setOpenDialog,
}: ICreatePermissionDialog) => {
    const [createPermissionsForm, setCreatePermissionsForm] = useState(true);
    const [deletePermissionForm, setDeletePermissionForm] = useState(false);

    const { mutateAsync: createPerm, isLoading: creatingPerm } =
        useCreatePerm();

    const { mutate: deletePerm, isLoading: deletingPerm } = useDeletePerm();

    const { mutateAsync: addPermsToGroup, isLoading: addingPermsToGroup } =
        useAddPermsToGroup();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(formSchema) });

    const { data: tamarindoPerms, isFetching: gettingTamarindoPerms } =
        useGroupPerms("Tamarindo Permissions");

    const PermissionsGridRef = useGridApiRef();

    const [permissionsGridColumnDefs] = useState<GridColDef[]>([
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCreatePerm = async (formData: FieldValues) => {
        await createPerm(formData.name)
            .then(
                async () =>
                    await addPermsToGroup({
                        group: "Tamarindo Permissions",
                        permissions: [formData.name],
                    })
                        .then(() => reset())
                        .catch(() => deletePerm(formData.name))
            )
            .catch();
    };

    const handleDeletePerms = () => {
        let selectedPerms: string = "";
        const selectedPermsFromGrid =
            PermissionsGridRef.current.getSelectedRows();
        const selectedPermsArray = Array.from(
            selectedPermsFromGrid.values()
        ) as IPermissions[];
        selectedPermsArray?.forEach((perm) => (selectedPerms = perm.name));
        deletePerm(selectedPerms);
    };

    const isLoading =
        creatingPerm ||
        deletingPerm ||
        addingPermsToGroup ||
        gettingTamarindoPerms;

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Gerenciar Permissões</DialogTitle>
            <form onSubmit={handleSubmit(handleCreatePerm)}>
                <Button
                    loading={isLoading}
                    onClick={() => {
                        setCreatePermissionsForm(true);
                        setDeletePermissionForm(false);
                    }}
                >
                    <Typography variant="body2">criar permissão</Typography>
                </Button>
                <Button
                    loading={isLoading}
                    onClick={() => {
                        setDeletePermissionForm(true);
                        setCreatePermissionsForm(false);
                    }}
                >
                    <Typography variant="body2">Excluir permissões</Typography>
                </Button>
                {createPermissionsForm && (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Após criar a permissão ela estará disponível
                                para ser atribuída aos usuários.
                            </DialogContentText>

                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="permission"
                                label="Nome da Permissão"
                                type="text"
                                variant="outlined"
                                error={!!errors?.name}
                                helperText={
                                    errors?.name?.message ? (
                                        <>{errors?.name?.message}</>
                                    ) : (
                                        <></>
                                    )
                                }
                                {...register("name", { required: true })}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button loading={isLoading} type="submit">
                                <Typography variant="body2">Criar</Typography>
                            </Button>
                        </DialogActions>
                    </>
                )}
                {deletePermissionForm && (
                    <>
                        <DialogContent>
                            <Typography variant="h4">
                                Permissões do grupo
                            </Typography>
                            <Box width={"100%"} height={"300px"}>
                                <DataGrid<IPermissions>
                                    apiRef={PermissionsGridRef}
                                    columns={permissionsGridColumnDefs}
                                    rows={tamarindoPerms ? tamarindoPerms : []}
                                    isRowSelectable={() => true}
                                    checkboxSelection
                                    loading={isLoading}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                loading={
                                    creatingPerm ||
                                    deletingPerm ||
                                    addingPermsToGroup
                                }
                                onClick={handleDeletePerms}
                            >
                                <Typography variant="body2">Excluir</Typography>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </form>
        </Dialog>
    );
};
