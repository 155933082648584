import { IconButton, Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface IComponentHeaderAction {
    tooltip: string;
    icon: ReactNode;
    action: () => void;
    disabled?: boolean;
}

const ComponentHeaderAction = ({
    tooltip,
    icon,
    action,
    disabled,
}: IComponentHeaderAction) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton disabled={disabled} onClick={action}>
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default ComponentHeaderAction;
