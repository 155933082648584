import { AxiosResponse } from 'axios';

import { api } from 'utils/Api/apiClient';

import { IVerifiedLeads } from 'interfaces/sdrApi/IVerifiedLeads';
import { IVerifiedLeadsQueryOptions } from 'interfaces/sdrApi/queriesOptions/IVerifiedLeadsQueryOptions';

import { SdrBasedGenericResponses } from 'utils/Api/responses/responses';

class VerifiedLeadsApi {
  endpoint: string;
  constructor() {
    this.endpoint = 'api/sdr/verified-leads/';
  }

  get = (params?: IVerifiedLeadsQueryOptions) =>
    api
      .get(this.endpoint, { params: params })
      .then((res: AxiosResponse<SdrBasedGenericResponses<IVerifiedLeads>>) => res.data);

  post = (object: IVerifiedLeads[] | FormData) =>
    object instanceof FormData
      ? api
          .post(this.endpoint, object, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res: AxiosResponse<IVerifiedLeads>) => res.data)
      : api.post(this.endpoint, object).then((res: AxiosResponse<IVerifiedLeads>) => res.data);

  put = ({ id, object }: { id: number; object: Partial<IVerifiedLeads> }) =>
    api.put(this.endpoint + `${id}/`, object).then(res => res.data);

  delete = (objectId: number) => api.delete(this.endpoint + `${objectId}/`).then(res => res.data);
}

export default VerifiedLeadsApi;
