import { animated } from '@react-spring/web';
import { useEffect, useState } from 'react';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './styles.css';

import { usePopup } from '../../hooks/usePopup';
import { TPopupMessage } from '../../@types/popup';

const icons = {
  info: <InfoOutlinedIcon sx={{ margin: '4px 12px 0 0' }} />,
  error: <InfoOutlinedIcon sx={{ margin: '4px 12px 0 0' }} />,
  success: <CheckCircleOutlinedIcon sx={{ margin: '4px 12px 0 0' }} />
};

const variantColorsPopup = {
  info: {
    background: '#ebf8ff',
    color: '#3172b7'
  },
  success: {
    background: '#e6fffa',
    color: '#2e656a'
  },
  error: {
    background: '#fddede',
    color: '#c53030'
  }
};

const variantColorsSpan = {
  info: {
    background: '#3172b7'
  },
  success: {
    background: '#2e656a'
  },
  error: {
    background: '#c53030'
  }
};

interface IPopup {
  message: TPopupMessage;
  style: any;
  duration: string;
}

export const Popup = ({ message, style, duration }: IPopup) => {
  const { removePopup } = usePopup();
  const [color, setColor] = useState({
    colorPopup: {
      background: '#ebf8ff',
      color: '#3172b7'
    },
    spanPopup: {
      background: '#3172b7'
    }
  });

  useEffect(() => {
    const alignItem = !message.description
      ? {
          'alignItems': 'center',
          '> svg': {
            marginTop: 0
          }
        }
      : {};

    setColor({
      colorPopup: {
        ...variantColorsPopup[message.type as keyof typeof variantColorsPopup],
        ...alignItem
      },
      spanPopup: variantColorsSpan[message.type as keyof typeof variantColorsPopup]
    });

    if (duration === 'temporary') {
      const timer = window.setTimeout(() => {
        removePopup(message.id);
      }, 7000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <animated.div className={'popupRoot'} style={{ ...color.colorPopup, ...style }}>
      {icons[(message.type as keyof typeof variantColorsPopup) || 'info']}

      <div className={'popupBox'}>
        <strong className={'strong'}>{message.title}</strong>
        {message.description && <p className={'description'}>{message.description}</p>}
      </div>

      <button onClick={() => removePopup(message.id)} className={'button'} type='button'>
        <HighlightOffOutlinedIcon />
      </button>
      {duration === 'temporary' && <span className={'popupSpan'} style={color.spanPopup}></span>}
    </animated.div>
  );
};
