import {
    MutationFunction,
    useMutation as useMutationHook,
    useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePopup } from "./usePopup";
import { SdrBasedErrors } from "../utils/Api/responses/responses";

interface IUseMutate<QR, T> {
    successText: string;
    errorText: string;
    queryKeyArray: string[];
    callback: MutationFunction<QR, T>;
}

const useMutation = <QR, T>({
    successText,
    errorText,
    queryKeyArray,
    callback,
}: IUseMutate<QR, T>) => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutationHook(callback, {
        onSuccess: () => {
            queryKeyArray.forEach((key) =>
                queryClient.invalidateQueries({
                    queryKey: [key],
                })
            );
            addPopup({
                title: successText,
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<SdrBasedErrors>) => {
            addPopup({
                title: errorText,
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useMutation;
