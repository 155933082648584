import { Token } from "../hooks/useAuth";
import { api } from "../utils/Api/apiClient";


const leadManagementService = {
    sendLeadsSpreadSheet: async (spreadSheet: FormData) => {
        const url = `api/sdr/v1/management/leads/`;

        return await api.post(url, spreadSheet, {
            headers: {
                Authorization: `Bearer ${Token()}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    attLeadsSpreadSheet: async (spreadSheet: FormData) => {
        const url = `api/sdr/v1/management/leads/`;

        return await api.patch(url, spreadSheet, {
            headers: {
                Authorization: `Bearer ${Token()}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    sendOutscraperSpreadSheet: async (spreadSheet: FormData) => {
        const url = `api/sdr/v1/management/outscraper-leads/`;

        return await api.post(url, spreadSheet, {
            headers: {
                Authorization: `Bearer ${Token()}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    attOutscraperSpreadSheet: async (spreadSheet: FormData) => {
        const url = `api/sdr/v1/management/outscraper-leads/`;

        return await api.patch(url, spreadSheet, {
            headers: {
                Authorization: `Bearer ${Token()}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },
};

export default leadManagementService;
