import React, { useState } from "react";

import Button from "../Button";
import DataGrid from "../DataGrid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";

import { IUser } from "../../interfaces/IUser";

import useUsers from "../../hooks/AcessApi/useUsers";
import useAddUsersToGroup from "../../hooks/AcessApi/useAddUsersToGroup";
import useGroupUsers from "../../hooks/AcessApi/useGroupUsers";

import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

interface IAddUsersToGroupDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    group?: string;
}

export const AddUserToGroupDialog = ({
    openDialog,
    setOpenDialog,
    group = "Groselha",
}: IAddUsersToGroupDialog) => {
    const { data: users, isFetching: gettingUsers } = useUsers();

    const { data: groselhaUsers, isFetching: gettingGroselhaUsers } =
        useGroupUsers("Groselha");

    const { mutate: addUsersToGroup, isLoading: addingUsersToGroup } =
        useAddUsersToGroup();

    const UsersGridRef = useGridApiRef();
    const [usersGridColumnDefs] = useState<GridColDef<IUser>[]>([
        {
            field: "username",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleAddUsersToGroup = () => {
        const selectedUsers = UsersGridRef.current.getSelectedRows();
        const selectedUsersArray = Array.from(
            selectedUsers.values()
        ) as IUser[];
        const usernames = selectedUsersArray
            ?.map((user) => user.username)
            .filter((username) => !!username);
        if (!!usernames?.length) {
            addUsersToGroup({ group: group, usernames: usernames as string[] });
        }
    };

    const gridRows =
        group === "Groselha"
            ? users?.filter(
                  (user) =>
                      !groselhaUsers?.some(
                          (groselhaUser) =>
                              user.username === groselhaUser.username
                      )
              )
            : groselhaUsers;

    const isLoading =
        gettingGroselhaUsers || gettingUsers || addingUsersToGroup;

    return (
        <div>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>Adicionar Usuários ao {group}</DialogTitle>

                <DialogContent>
                    <Box width={"100%"} height={"300px"}>
                        <DataGrid<IUser>
                            apiRef={UsersGridRef}
                            columns={usersGridColumnDefs}
                            rows={gridRows ? gridRows : []}
                            isRowSelectable={() => true}
                            checkboxSelection
                            loading={isLoading}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={addingUsersToGroup}
                        type="button"
                        onClick={handleClose}
                    >
                        <Typography variant="body2">sair</Typography>
                    </Button>
                    <Button
                        loading={addingUsersToGroup}
                        type="button"
                        onClick={handleAddUsersToGroup}
                    >
                        <Typography variant="body2">Adicionar</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
