import {
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    Typography,
} from "@mui/material";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { useLocation } from "react-router-dom";

interface IErrorsDialog {
    errors: any[];
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErrorsDialog = ({
    errors,
    openDialog,
    setOpenDialog,
}: IErrorsDialog) => {
    const handleClose = () => {
        setOpenDialog(false);
    };

    const location = useLocation();

    const locations: any = {
        "/classify": "Classificação",
        "/phone-validation": "Validação",
        "/enrichment": "Enriquecimento",
        "/filter": "Filtragem",
    };

    const handleExportLogs = () => {
        let logs = JSON.stringify(errors);
        let blob = new Blob([logs], { type: "text/plain" });
        let url = URL.createObjectURL(blob);

        let date = new Date();
        let link = document.createElement("a");
        link.download = `logs-de-erro-${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}-${date.getHours()}:${date.getMinutes()}.txt`;
        link.href = url;
        link.click();
    };

    return (
        <Dialog onClose={handleClose} open={openDialog}>
            <DialogTitle
                sx={{
                    backgroundColor: "#EFEFEF",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                Log de Erros
                <IconButton onClick={handleExportLogs}>
                    <FileDownloadOutlinedIcon
                        color="primary"
                        fontSize="large"
                    />
                </IconButton>
            </DialogTitle>
            <Divider />
            <List
                sx={{
                    maxHeight: "300px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                {errors.map((error, index) => (
                    <ListItem
                        disablePadding
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "start",
                            padding: "0px 10px",
                        }}
                        divider
                        key={index}
                    >
                        <h4>{error.message}</h4>
                        <Typography color={"secondary.light"} variant="body2">
                            {error.time} em {locations[location.pathname]}
                        </Typography>
                        <p style={{ wordWrap: "break-word", width: "100%" }}>
                            {error.error.length > 100 ? `${error.error.substring(0, 100)}...`: error.error}
                        </p>
                        {error.object && (
                            <p>
                                <strong>CNPJ do lead: </strong>
                                {error.object.cnpj}.
                            </p>
                        )}
                        {error.data && (
                            <p>
                                <strong>CNPJ do lead: </strong>
                                {error.data.cnpj}.
                            </p>
                        )}
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};
