import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface IComponentHeaderRoot {
    children: ReactNode;
}

const ComponentHeaderRoot = ({ children }: IComponentHeaderRoot) => {
    return (
        <Grid container maxWidth={"1440px"} marginBottom={"15px"}>
            {children}
        </Grid>
    );
};

export default ComponentHeaderRoot;
