import useAreas from 'hooks/useAreas';

import L, { LatLngExpression } from 'leaflet';
import { useEffect, useRef } from 'react';
import useRestaurants from 'hooks/useRestaurants';

import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import { usePopup } from 'hooks/usePopup';

interface IPolygon {
  lat: number;
  lng: number;
}

interface IDeliveryRegion {
  name: string;
  polygon: IPolygon[];
}

interface IRestaurantsWithoutDeliveryRegion {
  company_name: string;
  document: string;
  formatted_address: string;
  lng: number;
  lat: number;
}

const DeliveryRegionMap = () => {
  const { addPopup } = usePopup();

  const { isFetching: isFetchingAreas, data: areas, error: areasError } = useAreas({ directly: true });

  const {
    isFetching: isFetchingRestaurants,
    data: restaurants,
    error: restaurantsError
  } = useRestaurants({ visible: true });

  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    if (!isFetchingAreas && !areasError && !isFetchingRestaurants && !restaurantsError) {
      mapRef.current = L.map('mapArea', {
        center: [-23.6013319, -46.622806],
        zoom: 10,
        layers: [
          L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          })
        ]
      });

      areas?.results.forEach((area: IDeliveryRegion) => {
        const points: LatLngExpression[] = area.polygon.map((point: IPolygon) => {
          return [point.lat, point.lng];
        });

        const deliveryArea = L.polygon(points).addTo(mapRef.current!);
        deliveryArea.bindTooltip(`${area.name}`);
      });

      const icon = L.icon({
        iconUrl: markerIconPng
      });

      restaurants?.results.forEach((restaurant: IRestaurantsWithoutDeliveryRegion) => {
        const coordinates: LatLngExpression = [restaurant.lat, restaurant.lng];
        const restaurantMarker = L.marker(coordinates, { icon: icon }).addTo(mapRef.current!);
        restaurantMarker.bindPopup(
          `<p><b>${restaurant.company_name}</b></br>${restaurant.document}</p><p>${restaurant.formatted_address}</p>`
        );
      });
    }

    if (areasError) {
      addPopup({
        title: 'Falha ao buscar áreas de entrega.',
        type: 'error',
        duration: 'temporary'
      });

      if (restaurantsError) {
        addPopup({
          title: 'Falha ao buscar restaurantes sem área de entrega.',
          type: 'error',
          duration: 'temporary'
        });
      }
    }
  }, [
    mapRef,
    isFetchingAreas,
    isFetchingRestaurants,
    areasError,
    restaurantsError,
    areas?.results,
    addPopup,
    restaurants?.results
  ]);

  return (
    <div
      style={{
        height: 520,
        display: 'flex',
        justifyContent: 'center',
        margin: 20
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
        id='mapArea'
      >
        {(isFetchingAreas || isFetchingRestaurants) && <p>Carregando...</p>}
      </div>
    </div>
  );
};

export default DeliveryRegionMap;
