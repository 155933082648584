import { useQuery } from "@tanstack/react-query";
import AreaManagementService from "../services/areaManagementService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../utils/Api/responses/responses";
import { AxiosError } from "axios";

const areaManagementService = new AreaManagementService();

const useAreasLeadInfo = (query?: Object, definitions?: Object) =>
    useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["areas-lead-info", query],
        queryFn: () => areaManagementService.getAreasLeadsInfo(query),
        staleTime: 20 * 60 * 1000,
        keepPreviousData: true,
    });
export default useAreasLeadInfo;
