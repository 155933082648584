import React, {
    useState,
} from "react";
import Button from "../../../../../components/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@mui/material";

import { UserCard } from "../../../../../components/UserCard";
import { AlertDialog } from "../../../../../components/AlertDialog";

import usersService from "../../../../../services/usersService";

import { generateRandomPassword } from "../../../../../utils/randomPassword";

import { usePopup } from "../../../../../hooks/usePopup";
import { useAuth } from "../../../../../hooks/useAuth";
import { PermissionGate } from "../../../../../components/PermissionGate";

import { TUser } from "../../../../../@types/user";
import { AxiosError } from "axios";
import useGroupRemoveUsers from "../../../../../hooks/AcessApi/useGroupRemoveUsers";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import useDeleteUser from "../../../../../hooks/AcessApi/useDeleteUser";

interface IManageUserDialog {
    user: TUser;
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManageUserDialog = ({
    user,
    openDialog,
    setOpenDialog,
}: IManageUserDialog) => {
    const { addPopup } = usePopup();
    const { permissions } = useAuth();

    const adminPermission = ["tamarindo | admin"];

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [openConfirmRemoveUserDialog, setOpenConfirmRemoveUserDialog] =
        useState(false);
    const [openConfirmDeleteUserDialog, setOpenConfirmDeleteUserDialog] =
        useState(false);
    const [randomPwd, setRandomPwd] = useState("");
    const [resetingPassword, setResetingPassword] = useState(false);

    const { mutate: removeUsersFromGroup, isLoading: removingUserFromGroup } =
        useGroupRemoveUsers();

    const { mutate: deleteUser, isLoading: deletingUser } = useDeleteUser();

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleResetPassword = async () => {
        setResetingPassword(true);
        var randomPassword = generateRandomPassword();
        await usersService
            .changePassword(user.username, randomPassword)
            .then(() => {
                setRandomPwd(randomPassword);
                addPopup({
                    title: "Senha redefinida com sucesso",
                    type: "success",
                    duration: "temporary",
                });
                setOpenAlertDialog(true);
            })
            .catch(
                async (
                    err: AxiosError<{
                        error: string;
                    }>
                ) => {
                    addPopup({
                        title: "Falha ao redefinir senha.",
                        description: err.response?.data.error,
                        type: "error",
                        duration: "temporary",
                    });
                }
            )
            .finally(() => {
                setResetingPassword(false);
            });
    };

    const handleRemoveUserFromGroup = () => {
        removeUsersFromGroup({ group: "Tamarindo", usernames: [user.username] });
    };

    const handleDeleteUserFromGroup = () => {
        deleteUser(user.username);
    };

    return (
        <div>
            <Dialog
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "none",
                        padding: "20px 20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    },
                }}
                open={openDialog}
                onClose={handleClose}
            >
                <UserCard user={user} />
                <DialogActions
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <PermissionGate
                        neededpermissions={adminPermission}
                        userPermissions={permissions}
                    >
                        <Box sx={{ display: "flex" }}>
                            <Button
                                onClick={() =>
                                    setOpenConfirmDeleteUserDialog(true)
                                }
                                loading={deletingUser}
                            >
                                Excluir Usuário
                            </Button>
                            <Button
                                onClick={() =>
                                    setOpenConfirmRemoveUserDialog(true)
                                }
                                loading={removingUserFromGroup}
                            >
                                Remover do Tamarindo
                            </Button>
                            <Button
                                loading={resetingPassword}
                                onClick={handleResetPassword}
                            >
                                Resetar Senha
                            </Button>
                        </Box>
                    </PermissionGate>
                </DialogActions>
            </Dialog>
            {openAlertDialog && (
                <AlertDialog
                    title={`Repasse essa senha ao usuário: ${randomPwd}`}
                    message="Essa senha foi gerada automaticamente e o usuário poderá alterá-la ao fazer o login."
                    openDialog={openAlertDialog}
                    setOpenDialog={setOpenAlertDialog}
                />
            )}
            {openConfirmRemoveUserDialog && (
                <ConfirmationDialog
                    title="Deseja mesmo remover o usuário do grupo Tamarindo?"
                    message="Caso confirme o usuário perderá acesso a toda interface do Tamarindo Web."
                    openDialog={openConfirmRemoveUserDialog}
                    setOpenDialog={setOpenConfirmRemoveUserDialog}
                    callback={handleRemoveUserFromGroup}
                />
            )}
            {openConfirmDeleteUserDialog && (
                <ConfirmationDialog
                    title="Deseja mesmo deletar o usuário?"
                    message="Caso confirme o usuário perderá acesso a todos os sistemas da frexco."
                    openDialog={openConfirmDeleteUserDialog}
                    setOpenDialog={setOpenConfirmDeleteUserDialog}
                    callback={handleDeleteUserFromGroup}
                />
            )}
        </div>
    );
};
