import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface IComponentHeaderActions {
    children: ReactNode;
}

const ComponentHeaderActions = ({ children }: IComponentHeaderActions) => {
    return (
        <Grid item xs={12} sm={6} display={"flex"} justifyContent={"end"}>
            {children}
        </Grid>
    );
};

export default ComponentHeaderActions;
