import { Navigate } from "react-router-dom";

interface IProtectedRoute {
  children: any;
  redirectPath: string;
  isAllowed: boolean;
}

export const ProtectedRoute = ({
  children,
  redirectPath = '/',
  isAllowed
}: IProtectedRoute) => {
  if(!isAllowed){
    return <Navigate to={redirectPath} replace />
  }

  return children;
};
