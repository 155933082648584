import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";
import { AlertDialog } from "../../../../../components/AlertDialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { generateRandomPassword } from "../../../../../utils/randomPassword";

import useCreateUser from "../../../../../hooks/AcessApi/useCreateUser";
import useAddUsersToGroup from "../../../../../hooks/AcessApi/useAddUsersToGroup";
import { Typography } from "@mui/material";

let formSchema = yup.object().shape({
    username: yup
        .string()
        .required("Nome do usuário é obrigatório")
        .matches(/^[a-zA-Z0-9._-]+$/, "Contém caracteres inválidos."),
});

export const CreateUserForm = () => {
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [randomPwd, setRandomPwd] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(formSchema) });

    const { mutateAsync: createUser, isLoading: creatingUser } =
        useCreateUser();

    const { mutate: addUserToGroup, isLoading: addingUserToGroup } =
        useAddUsersToGroup();

    const handleCreateUser = async (formData: FieldValues) => {
        await createUser({
            username: formData.username,
            password: randomPwd,
        })
            .then(() => {
                addUserToGroup({
                    group: "Tamarindo",
                    usernames: [formData.username],
                });
                reset();
                setOpenAlertDialog(true);
            })
            .catch();
    };

    useEffect(() => {
        setRandomPwd(generateRandomPassword());
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(handleCreateUser)}>
                <DialogContent
                    sx={{
                        "& .MuiTextField-root": {
                            margin: "0px",
                        },
                    }}
                >
                    <Typography sx={{ marginBottom: "5px" }} variant="h4">
                        Criar Usuário
                    </Typography>
                    <TextField
                        autoFocus
                        fullWidth
                        margin="dense"
                        id="permission"
                        label="Nome do Usuário"
                        type="text"
                        variant="outlined"
                        error={!!errors?.username}
                        helperText={
                            errors?.namusernamee?.message ? (
                                <>{errors?.username?.message}</>
                            ) : (
                                <></>
                            )
                        }
                        {...register("username", { required: true })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={creatingUser || addingUserToGroup}
                        type="submit"
                    >
                        Criar
                    </Button>
                </DialogActions>
            </form>
            <AlertDialog
                title={`Repasse esta senha ao usuário: ${randomPwd}`}
                message="Essa senha foi gerada automáticamente. O usuário poderá alterá-la ao fazer o login."
                openDialog={openAlertDialog}
                setOpenDialog={setOpenAlertDialog}
            />
        </>
    );
};
