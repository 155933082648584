interface IPermissionGate {
  neededpermissions: string[];
  userPermissions: string[];
  children: any;
}

export const PermissionGate = ({ neededpermissions, userPermissions, children }: IPermissionGate) => {
  const matchPermission = neededpermissions.some(
    permission => userPermissions.includes(permission) && userPermissions.includes('tamarindo | user')
  );
  if (matchPermission || neededpermissions.length === 0 || userPermissions.includes('tamarindo | admin')) {
    return children;
  }

  return null;
};
