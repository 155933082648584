import ServiceTitle from 'components/ServiceTitle';
import DeliveryRegionMap from './components/DeliveryRegionMap';

const DeliveryRegion = () => {
  return (
    <>
      <ServiceTitle title='Restaurantes sem área de entrega' />
      <DeliveryRegionMap />
    </>
  );
};

export default DeliveryRegion;
