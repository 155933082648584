import { api } from "../utils/Api/apiClient";

class GroupsService {
    url: string;
    constructor() {
        this.url = `api/access/group/`;
    }

    createGroup = (name: string) =>
        api.post(this.url, { name: name }).then((res) => res.data);

    deleteGroup = (name: string) =>
        api.delete(this.url + `${name}`).then((res) => res.data);

    getGroupUsers = (group: string) =>
        api.get(this.url + `users/${group}`).then((res) => res.data);

    addUsersToGroup = ({
        group,
        usernames,
    }: {
        group: string;
        usernames: string[];
    }) =>
        api
            .post(this.url + "users/", {
                group_name: group,
                users: usernames,
            })
            .then((res) => res.data);

    removeUsersFromGroup = ({
        group,
        usernames,
    }: {
        group: string;
        usernames: string[];
    }) =>
        api
            .delete(this.url + "users/", {
                data: { group_name: group, users: usernames },
            })
            .then((res) => res.data);

    getGroupPerms = (group: string) =>
        api.get(this.url + `permissions/${group}`).then((res) => res.data);

    addPermsToGroup = ({
        group,
        permissions,
    }: {
        group: string;
        permissions: string[];
    }) =>
        api
            .post(this.url + "permissions/", {
                group_name: group,
                permissions: permissions,
            })
            .then((res) => res.data);

    removePermsFromGroup = ({
        group,
        permissions,
    }: {
        group: string;
        permissions: string[];
    }) =>
        api
            .delete(this.url + "permissions/", {
                data: {
                    group_name: group,
                    permissions: permissions,
                },
            })
            .then((res) => res.data);
}

export default GroupsService;
