import { api } from "../utils/Api/apiClient";

class UsersService {
    url: string;
    constructor() {
        this.url = `api/access/user`;
    }

    getAllUsers = () => api.get(this.url).then((res) => res.data);

    createUser = ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) =>
        api
            .post(this.url, {
                username: username,
                password: password,
            })
            .then((res) => res.data);

    deleteUser = (username: string) =>
        api.delete(this.url + `/${username}`).then((res) => res.data);

    getUserPerms = (name: string) =>
        api.get(this.url + `/permission/${name}`).then((res) => res.data);

    changePassword = (username: string, password: string) => {
        api.patch(this.url + username, { password: password }).then(
            (res) => res.data
        );
    };
}

export default UsersService;
