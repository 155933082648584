import StepConnector, { StepConnectorProps } from '@mui/material/StepConnector';
export const Connector = (props: StepConnectorProps) => {
  return (
    <StepConnector
      classes={{
        alternativeLabel: 'stepAlternativeLabel',
        line: 'stepLine'
      }}
      sx={{
        '& $line': {
          borderColor: props.classes?.active || props.classes?.completed ? '#89BD23' : '#EAEAF0'
        }
      }}
    ></StepConnector>
  );
};
