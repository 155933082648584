import { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

interface IComponentHeaderDescription {
    text: string | ReactNode;
}

const ComponentHeaderDescription = ({ text }: IComponentHeaderDescription) => {
    return (
        <Grid item marginTop={"10px"} xs={12}>
            <Typography variant="body1" color={"secondary.light"}>
                Detalhes do serviço
            </Typography>
            <Typography variant="body1">{text}</Typography>
        </Grid>
    );
};

export default ComponentHeaderDescription;
