import { useMutation } from "@tanstack/react-query";
import PermissionsService from "../../services/permissionServiceClass";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const permissionsService = new PermissionsService();

const useCreatePerm = () => {
    const { addPopup } = usePopup();
    return useMutation(permissionsService.createPerm, {
        onSuccess: () => {
            addPopup({
                title: "Permissão criada com sucesso.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao criar permissão.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useCreatePerm;
