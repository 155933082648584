import Box from "@mui/material/Box";
import { useState } from "react";
import { Content } from "./Content";
import { NavBar } from "./NavBar";
import SideBar from "./Sidebar";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <NavBar toggleDrawer={toggleDrawer} openDrawer={openDrawer} />
      <SideBar openDrawer={openDrawer} onClose={toggleDrawer} />
      <Content>{children}</Content>
    </Box>
  );
};

export default Layout;
