import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TAuthContext } from "../@types/auth";

import authenticationService from "../services/authenticationService";
import permissionsService from "../services/permissionService";
import { api } from "../utils/Api/apiClient";
import { usePopup } from "./usePopup";
import { ErrorResponse } from "../utils/Api/errors/Errors";

const AuthContext = createContext({} as TAuthContext);

class AuthError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "AuthError";
    }
}

interface ISignIn {
    username: string;
    password: string;
}

interface IAuthProvider {
    children: React.ReactNode;
}

const AuthProvider = ({ children }: IAuthProvider) => {
    const { addPopup } = usePopup();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState(() => {
        const item = localStorage.getItem("permissions");
        if (typeof item === "string") {
            const permissionList = JSON.parse(item);
            if (permissionList) return permissionList;
        }
        return [];
    });

    const [token, setToken] = useState(() => {
        const token = localStorage.getItem("access");
        if (token) return token;

        return null;
    });

    const [user, setUser] = useState(() => {
        const user = localStorage.getItem("user");
        if (user) return user;
        return "";
    });

    const [refresh, setRefresh] = useState(() => {
        const refresh = localStorage.getItem("refresh");
        if (refresh) return refresh;

        return null;
    });

    const signIn = async ({ username, password }: ISignIn) => {
        try {
            const { data } = await authenticationService.login({
                username,
                password,
            });
            const access = data.access;
            const refresh = data.refresh;
            const user = username;
            localStorage.setItem("access", access);
            localStorage.setItem("refresh", refresh);
            localStorage.setItem("user", user);
            api.defaults.headers.common["Authorization"] = `Bearer ${Token()}`;
            setUser(user);
            setToken(access);
            setRefresh(refresh);
            await getPermissions(username);
            navigate("/");
        } catch (error: any) {
            const statusCode = error?.response?.status;
            if (statusCode === 401) {
                throw new AuthError(error?.message);
            } else {
                throw new Error();
            }
        }
    };

    const signOut = useCallback(() => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user");

        setPermissions([]);

        setToken(null);
        setRefresh(null);
        setUser("");

        navigate("/login");
    }, [navigate]);

    const getRules = useCallback(async (username: string) => {
        try {
            await getPermissions(username);
            setUser(username);
        } catch {}
    }, []);

    const getPermissions = async (username: string) => {
        try {
            const { data } = await permissionsService.getPerms(username);
            const payload = data.map(({ name }: { name: string }) => name);
            setPermissions(payload);
            localStorage.setItem("permissions", JSON.stringify(payload));
        } catch {
            toast.error("Erro ao procurar permissões");
            return;
        }
    };

    const refreshValidator = useCallback(() => {
        if (typeof token === "string") {
            try {

                authenticationService
                    .refresh(refresh)
                    .then(({ data }) => {
                        localStorage.setItem("access", data.access);
                        setToken(data.access);
                    })
                    .catch((err) => {
                        let title: string;
                        let description: string;
                        title =
                            err instanceof ErrorResponse || err instanceof Error
                                ? "Falha ao renovar token."
                                : "Erro desconhecido.";

                        description = err.message;
                        addPopup({
                            title: title,
                            description: description,
                            type: "error",
                            duration: "temporary",
                        });
                    });

                addPopup({
                    type: "info",
                    title: "Renovando seu Token!",
                    duration: "temporary",
                });
            } catch (err) {
                signOut();
                addPopup({
                    type: "info",
                    title: "Login expirado",
                    duration: "temporary",
                });
            }
        }
    }, [addPopup, refresh, signOut, token]);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshValidator();
        }, 30 * 60000);
        if (localStorage.getItem("user")) {
            getRules(user);
        }
        return () => {
            clearInterval(interval);
        };
    }, [refreshValidator, user, getRules]);

    api.defaults.headers.common["Authorization"] = `Bearer ${Token()}`;

    return (
        <AuthContext.Provider
            value={{ token, permissions, user, signIn, signOut }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

const Token = () => {
    const token = localStorage.getItem("access");
    if (token) {
        return token;
    } else {
        const refresh = localStorage.getItem("refresh");
        if (refresh) {
            return refresh;
        } else {
            return false;
        }
    }
};

export { AuthProvider, useAuth, AuthError, Token };
