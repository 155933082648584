import { Card as MuiCard, Avatar, Typography } from '@mui/material';

import { stringAvatar } from '../../utils/stringAvatar';
import { stringToColor } from '../../utils/stringToColor';

import { TUser } from '../../@types/user';

import './styles.css';

interface IUserCard {
  user: TUser;
  onClick?: any;
}

export const UserCard = ({ user, onClick }: IUserCard) => {
  return (
    <>
      <MuiCard onClick={onClick} className={'cardRoot'}>
        <Avatar sx={{ background: stringToColor(user.username) }} className={'avatar'}>
          {stringAvatar(user.username)}
        </Avatar>
        <Typography className={'name'} variant='body2'>
          {user.username}
        </Typography>
      </MuiCard>
    </>
  );
};
