import axios from "axios";

import { MS_API } from "../../settings";

export const setupApiClient = () => {
    const api = axios.create({
        baseURL: MS_API,
        headers: {
            "Content-Type": "application/json",
        },
    });

    return api;
};
