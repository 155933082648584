import { useState } from "react";

import { IUser } from "../../../../../interfaces/IUser";

import { Box, Typography } from "@mui/material";

import Button from "../../../../../components/Button";
import DataGrid from "../../../../../components/DataGrid";

import useGroupUsers from "../../../../../hooks/AcessApi/useGroupUsers";
import useGroupRemoveUsers from "../../../../../hooks/AcessApi/useGroupRemoveUsers";

import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

export const RemoveUserFromGroupList = ({ group }: { group: string }) => {
    const { data: groupUsers, isFetching: gettingGroupUsers } =
        useGroupUsers(group);

    const { mutate: removeUsersFromGroup, isLoading: removingUsersFromGroup } =
        useGroupRemoveUsers();

    const UsersGridRef = useGridApiRef();
    const [usersGridColumnDefs] = useState<GridColDef<IUser>[]>([
        {
            field: "username",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const handleRemoveUserFromGroup = () => {
        const selectedUsers = UsersGridRef.current.getSelectedRows();
        const selectedUsersArray = Array.from(
            selectedUsers.values()
        ) as IUser[];
        const usernames = selectedUsersArray
            ?.map((user) => user.username)
            .filter((user) => typeof user === "string") as string[];
        removeUsersFromGroup({ group: group, usernames: usernames });
    };

    const isLoading = gettingGroupUsers || removingUsersFromGroup;

    return (
        <Box>
            <Typography sx={{ marginBottom: "5px" }} variant="h4">
                Remover Usuário
            </Typography>
            <Box width={"100%"} height={"300px"}>
                <DataGrid
                    apiRef={UsersGridRef}
                    columns={usersGridColumnDefs}
                    rows={groupUsers ? groupUsers : []}
                    isRowSelectable={() => true}
                    checkboxSelection
                    loading={isLoading}
                />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <Button
                    onClick={handleRemoveUserFromGroup}
                    loading={removingUsersFromGroup}
                >
                    <Typography variant="body2">Remover</Typography>
                </Button>
            </Box>
        </Box>
    );
};
