import { useQuery } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";
import { IPermissions } from "../../interfaces/IPermissions";

const groupService = new GroupsService();

const useGroupPerms = (group: string) =>
    useQuery<IPermissions[], PermissionsApiErros>({
        queryKey: ["group-perms", group],
        queryFn: () => groupService.getGroupPerms(group),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: `Erro ao buscar permissões do ${group}.`,
        },
    });
export default useGroupPerms;
