import { api } from "../utils/Api/apiClient";

interface ILogin {
  username: string;
  password: string;
}

const login = async ({ username, password }: ILogin) => {
  const url = "/api/abacate/token";
  return await api.post(url, {
    username,
    password,
  });
};

const refresh = async (refresh: string | null) => {
  const url = "/api/abacate/token/refresh";
  return await api.post(url, {
    refresh,
  });
};

const authenticationService = {
  login,
  refresh,
};

export default authenticationService;
