import { Typography, Grid } from "@mui/material";

interface IComponentHeaderTitle {
    text: string;
}

const ComponentHeaderTitle = ({ text }: IComponentHeaderTitle) => {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            sx={{
                width: "fit-content",
                height: "3.13rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h3"
                sx={{
                    color: "secondary.main",
                    display: "flex",
                    alignItems: "center",
                    "&.MuiTypography-root::before": {
                        content: `""`,
                        display: "inline-block",
                        backgroundColor: "primary.main",
                        width: 8,
                        height: 50,
                        marginRight: "1.5rem",
                        borderRadius: 5,
                    },
                }}
            >
                {text.toUpperCase()}
            </Typography>
        </Grid>
    );
};

export default ComponentHeaderTitle;
