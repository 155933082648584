import ComponentHeaderAction from "./ComponentHeaderAction";
import ComponentHeaderActions from "./ComponentHeaderActions";
import ComponentHeaderDescription from "./ComponentHeaderDescription";
import ComponentHeaderRoot from "./ComponentHeaderRoot";
import ComponentHeaderTitle from "./ComponentHeaderTitle";

const ComponentHeader = {
    Root: ComponentHeaderRoot,
    Title: ComponentHeaderTitle,
    Actions: ComponentHeaderActions,
    Action: ComponentHeaderAction,
    Desc: ComponentHeaderDescription,
};

export default ComponentHeader;
