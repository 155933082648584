import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { PopupProvider } from "./hooks/usePopup";

import Routes from "./routes/Routes";

import GlobalStyle from "./styles/global";
import theme from "./styles/theme";
import { ApiInterceptor } from "./utils/Api/interceptor";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 2,
                cacheTime: 300_000,
                staleTime: 10_000,
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <PopupProvider>
                        <AuthProvider>
                            <ApiInterceptor>
                                <QueryClientProvider client={queryClient}>
                                    <Routes />
                                </QueryClientProvider>
                            </ApiInterceptor>
                        </AuthProvider>
                    </PopupProvider>
                </ThemeProvider>
            </BrowserRouter>
        </LocalizationProvider>
    );
}

export default App;
