import { useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DataGrid from 'components/DataGrid';

interface ILead {
  lead_information_id: string;
  rdstation_user_name: string;
  rdstation_stage_nickname: string;
}

interface ICorrectRecords {
  correctRecords: ILead[] | [];
}

const CorrectRecords = ({ correctRecords }: ICorrectRecords) => {
  const [columnDefs] = useState<GridColDef<ILead>[]>([
    {
      field: 'lead_information_id',
      headerName: 'Registros corretos',
      flex: 1
    },
    {
      field: 'rdstation_user_name',
      headerName: '',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'rdstation_stage_nickname',
      headerName: '',
      minWidth: 100,
      flex: 1
    }
  ]);

  return (
    <Box width={'100%'} height={'300px'}>
      <DataGrid<ILead> rows={correctRecords} columns={columnDefs} getRowId={row => row.lead_information_id} />
    </Box>
  );
};

export default CorrectRecords;
