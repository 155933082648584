import {
    DataGridProps,
    GridValidRowModel,
    DataGrid as MuiDataGrid,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "./components/CustomNoROwsOverlay/indext";

const DataGrid = <T extends GridValidRowModel>(options: DataGridProps<T>) => {
    return (
        <MuiDataGrid<T>
            pageSizeOptions={[]}
            isRowSelectable={() => false}
            hideFooterSelectedRowCount
            hideFooterPagination
            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            sx={{
                "& .MuiDataGrid-row.row--removed": {
                    backgroundColor: (theme) => theme.palette.error.main,
                },
                "& .MuiDataGrid-row.row--edited": {
                    backgroundColor: (theme) => theme.palette.warning.light,
                },
                "& .success": {
                    backgroundColor: (theme) => theme.palette.primary.light,
                },
                "& .warning": {
                    backgroundColor: (theme) => theme.palette.warning.light,
                },
                "& .fail": {
                    backgroundColor: (theme) => theme.palette.error.main,
                },
            }}
            {...options}
        />
    );
};

export default DataGrid;
