import { styled } from "@mui/material";
import { Fab } from "@mui/material";

const FloatingButton = styled(Fab)(({ theme }) => ({
    color: "#CF142B",
    position: "absolute",
    bottom: 50,
    right: 30,
    boxShadow: "0px 0px 0px 0px rgb(207, 20, 43, .5)",
    "@keyframes pulsate": {
        "0%": {
            transform: "scale(.9)",
        },
        "70%": {
            transform: "scale(1.2)",
            boxShadow: "0px 0px 0px 30px rgba(0, 0, 0, 0)"
        },
        "100%": {
            transform: "scale(.9)",
            boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
        },
    },
    animation: "pulsate 1.5s infinite ease",
    
}));

export default FloatingButton;
