import { api } from "../utils/Api/apiClient";

const getPerms = async (username: string) => {
    const url = `/api/access/user/permission/${username}`;
    return await api.get(url);
};

const addPermsToUser = async (username: string, permission: string) => {
    const url = `/api/access/user/permission/`;
    const body = {
        username: username,
        permission: permission,
    };
    return await api.post(url, body);
};

const deleteUserPerm = async (username: string, permission: string) => {
    const url = `/api/access/user/permission/`;
    const body = {
        username: username,
        permission: permission,
    };
    return await api.delete(url, { data: body  });
};

const getGroupPerms = async (name: string) => {
    const url = `/api/access/group/permissions/${name}`;
    return await api.get(url);
};

const createPerm = async (name: string) => {
    const url = `/api/access/permission`;
    const body = {
        name: name,
    };
    return await api.post(url, body);
};

const deletePerm = async (name: string) => {
    const url = `/api/access/permission/${name}`;
    return await api.delete(url);
};

const addPermToGroup = async (permissions: string[]) => {
    const url = `/api/access/group/permissions/`;
    const body = {
        group_name: "Tamarindo Permissions",
        permissions: permissions,
    };
    return await api.post(url, body);
};

const permissionsService = {
    getPerms,
    addPermsToUser,
    deleteUserPerm,
    getGroupPerms,
    createPerm,
    deletePerm,
    addPermToGroup,
};

export default permissionsService;
