import { useState, useEffect, useCallback } from "react";

import {
    Box,
    Typography,
    List,
    ListItem,
    CircularProgress,
} from "@mui/material";

import ServiceTitle from "../../../components/ServiceTitle";
import Button from "../../../components/Button";
import { ChangePwdDialog } from "../../../components/ChangePwdDialog";

import permissionsService from "../../../services/permissionService";

import { usePopup } from "../../../hooks/usePopup";
import { AxiosError } from "axios";

import "./styles.css";

type TPerm = {
    name: string;
};

const Profile = () => {
    const { addPopup } = usePopup();

    const [gettingPerms, setGettingPerms] = useState(false);
    const [openChangePwdDialog, setOpenChangePwdDialog] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);

    const handleOpenPwdDialog = () => {
        setOpenChangePwdDialog(true);
    };

    const getUserPermissions = useCallback(async () => {
        setGettingPerms(true);
        await permissionsService
            .getPerms(localStorage.getItem("user")!)
            .then((res) => {
                setUserPermissions(res.data);
            })
            .catch(
                async (
                    err: AxiosError<{
                        error: string;
                    }>
                ) => {
                    addPopup({
                        title: "Falha ao buscar permissões.",
                        description: err.response?.data.error,
                        type: "error",
                        duration: "temporary",
                    });
                }
            )
            .finally(() => {
                setGettingPerms(false);
            });
    }, [addPopup]);

    useEffect(() => {
        getUserPermissions();
    }, [getUserPermissions]);

    return (
        <>
            <Box className={"header"}>
                <ServiceTitle title="Perfil" />
                <Button onClick={handleOpenPwdDialog}>
                    <Typography variant="h6">Alterar Senha</Typography>
                </Button>
            </Box>
            <Box className={"contentContainer"}>
                <Box className={"permsContainer"}>
                    <Typography variant="h6">
                        Suas Permissões:
                        <List
                            sx={{
                                maxHeight: "200px",
                                overflowY: "scroll",
                                backgroundColor: "#F0F0F0",
                            }}
                            dense={true}
                        >
                            {gettingPerms ? (
                                <CircularProgress sx={{ marginLeft: "10px" }} />
                            ) : (
                                userPermissions.map((perm: TPerm, index) => (
                                    <ListItem key={index}>
                                        <Typography variant="h4">
                                            {perm.name}
                                        </Typography>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    </Typography>
                </Box>
            </Box>

            {openChangePwdDialog && (
                <ChangePwdDialog
                    openDialog={openChangePwdDialog}
                    setOpenDialog={setOpenChangePwdDialog}
                />
            )}
        </>
    );
};

export default Profile;
