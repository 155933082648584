import { useMutation, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { PermissionsApiErros } from "../../utils/Api/responses/responses";

const groupsService = new GroupsService();

const useAddUsersToGroup = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(groupsService.addUsersToGroup, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey.every((name) =>
                        ["group-users", variables.group].includes(
                            name as string
                        )
                    ) ||
                    query.queryKey.every((name) =>
                        ["group-users", "Tamarindo"].includes(name as string)
                    ) ||
                    query.queryKey.every((name) =>
                        ["users"].includes(name as string)
                    ),
            });
            addPopup({
                title: "Usuários adicionados ao grupo.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<PermissionsApiErros>) => {
            addPopup({
                title: "Erro ao adicionar usuários ao grupo.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useAddUsersToGroup;
