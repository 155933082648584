import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

const Button = styled(MuiLoadingButton)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: "0.625rem 1.25rem",
  textTransform: "capitalize",
  borderRadius: "0.15rem",
  backgroundColor: "#8BC34A",
  color: "#FAFAFA",
  "&:hover": {
    backgroundColor: "#689F38",
  },
  "& .MuiLoadingButton-loadingIndicator": {
    color: "#FAFAFA",
  },
}));

export default Button;
