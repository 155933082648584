import clsx from 'clsx';
import { Check } from '@mui/icons-material';

import './styles.css';

export const StepIcon = (props: any) => {
  const { active, completed } = props;

  return (
    <div
      className={clsx('stepIconRoot', {
        ['stepIconActive']: active
      })}
    >
      {completed ? <Check className={'stepIconCompleted'} /> : <div className={'stepIconCircle'} />}
    </div>
  );
};
