import { api } from "../utils/Api/apiClient";

class PermissionsService {
    url: string;
    constructor() {
        this.url = `api/access/permission`;
    }

    createPerm = (name: string) =>
        api.post(this.url, { name: name }).then((res) => res.data);

    deletePerm = (name: string) => api.delete(this.url + `/${name}`);
}

export default PermissionsService;
