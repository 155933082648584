import { useMutation, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { usePopup } from "../usePopup";

const groupsService = new GroupsService();

const useGroupRemoveUsers = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(groupsService.removeUsersFromGroup, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(["group-users", variables.group]);
            addPopup({
                title: `Usuário removido do grupo ${variables.group}.`,
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error, variables) => {
            addPopup({
                title: `Erro ao remover usuário(s) do grupo ${variables.group}`,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useGroupRemoveUsers;
