import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: theme.spacing(1),
  "& .MuiInputAdornment-root svg": {
    color: "#A4A4A4",
  },
  "& .Mui-focused .MuiInputAdornment-positionStart svg": {
    color: "#8BC34A",
  },
  "& label.Mui-focused": {
    color: "#8BC34A",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8BC34A",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A4A4A4",
    },
    "&:hover fieldset": {
      borderColor: "#8BC34A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8BC34A",
    },
  },
}));

export default TextField;
