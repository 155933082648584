import { useTransition } from "@react-spring/web";

import { Popup } from "./popUp";

import { TPopupMessage } from "../../@types/popup";

interface IPopupContainer {
  messages: TPopupMessage[];
  duration: string;
}

export const PopupContainer = ({ messages, duration }: IPopupContainer) => {

  const transition = useTransition(messages, {
    keys: (message: TPopupMessage) => message.id,
    from: { right: "-120%", opacity: 0 },
    enter: { right: "0%", opacity: 1 },
    leave: { right: "-120%", opacity: 0 },
  });

  return (
    <div className={"popupContainer"}>
      {transition((style: any, popup: any) => (
        <Popup style={style} message={popup} duration={duration} />
      ))}
    </div>
  );
};
