import { Routes as Switch, Route as ReactRoute } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RouteList from "./RouteList";
import { ProtectedRoute } from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import Layout from "../layouts";

const Routes = () => {
    const { token, permissions } = useAuth();
    return (
        <Switch>
            {RouteList.map(({ path, element, requiredPermissions }, index) =>
                path !== "/login" ? (
                    <ReactRoute
                        key={index}
                        path={path}
                        element={
                            <ProtectedRoute
                                children={<Layout>{element}</Layout>}
                                redirectPath={!!token ? "/" : "/login"}
                                isAllowed={
                                    !!token &&
                                    (requiredPermissions.length === 0 ||
                                        permissions.includes(
                                            "tamarindo | admin"
                                        ) ||
                                        requiredPermissions.some((permission) =>
                                            permissions.includes(permission)
                                        ))
                                }
                            />
                        }
                    />
                ) : !!token ? (
                    <ReactRoute
                        key={index}
                        path={path}
                        element={<Navigate to={"/"} replace />}
                    />
                ) : (
                    <ReactRoute key={index} path={path} element={element} />
                )
            )}
        </Switch>
    );
};

export default Routes;
