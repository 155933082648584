import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useWebSocket } from '../../../hooks/useWebSocket';
import { useSpreadSheet } from '../../../hooks/useSpreadSheet';
import { TemplateData } from '../../../mock/templateData';

import Button from '../../../components/Button';
import ServiceTitle from '../../../components/ServiceTitle';
import InfoCard from '../../../components/InfoCard';
import FloatingButton from '../../../components/FloatingButton';
import { ErrorsDialog } from '../../../components/ErrorsDialog';

import './styles.css';
import ComponentHeader from 'components/ComponentHeader';

const Filter = () => {
  const uploadFile = useRef<any>();

  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const { isReady, send, setProcessingData, processingData, percentage, errorsArray } = useWebSocket();

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };

      reader.onloadend = () => {};
    } catch {}
  };

  const { exportToExcel, creatingExcel } = useSpreadSheet();

  const onDownloadClick = () => {
    exportToExcel(TemplateData, 'Filtragem de leads - exemplo de entrada');
  };

  return (
    <div>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Filtragem de Leads' />
        <ComponentHeader.Desc text='Filtra os dados, indicando quais leads já estão registrados no banco de dados.' />
      </ComponentHeader.Root>

      <Box className={'contentContainer'}>
        <Box className={'infoContainer'}>
          <InfoCard
            icon={
              isReady ? (
                <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
              ) : (
                <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
              )
            }
            title='Status de Conexão'
            value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
          />

          <InfoCard
            icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Progresso'
            value={`${percentage.toFixed(2).toString() || 0} %`}
          />

          <Box className={'buttonsContainer'}>
            <Button
              loading={creatingExcel}
              onClick={onDownloadClick}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              <Typography variant='body2'>EXEMPLO DE ENTRADA</Typography>
            </Button>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={processingData}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
              disabled={!isReady}
            >
              <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
            </Button>
          </Box>
          <input
            style={{ display: 'none' }}
            id='filter-lead'
            type='file'
            ref={uploadFile}
            onChange={event => onFileUpload(event)}
          />
        </Box>
        {errorsArray.length > 0 && (
          <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
            <InfoOutlinedIcon />
          </FloatingButton>
        )}
      </Box>
      <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
    </div>
  );
};

export default Filter;
