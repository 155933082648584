import { Box } from '@mui/material';

import UsersManagementPanel from './components/UsersManagementPanel';

import ComponentHeader from '../../../components/ComponentHeader';

const AdminPanel = () => {
  return (
    <>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Painel de Administração' />
      </ComponentHeader.Root>
      <Box marginTop={'15px'}>
        <UsersManagementPanel />
      </Box>
    </>
  );
};

export default AdminPanel;
