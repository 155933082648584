import { ReactNode, useState } from "react";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

import { CreateUserForm } from "../CreateUserForm";
import { AddUserToGroupDialog } from "../../../../../components/AddUserToGroup";
import { RemoveUserFromGroupList } from "../RemoveUserFromGroupList";
import { DeleteUserList } from "../DeleteUserList";

import theme from "../../../../../styles/theme";

interface IManageUserDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IButtonInfo {
    label: string;
    stateKey: string;
    icon: ReactNode;
}

export const ManageUsersDialog = ({
    openDialog,
    setOpenDialog,
}: IManageUserDialog) => {
    const [showAddUsersComponent, setShowAddUsersComponent] = useState(false);

    const [activeButton, setActiveButtonIndex] = useState<IButtonInfo | null>({
        label: "Criar Usuário",
        stateKey: "showCreateUserForm",
        icon: <AddReactionOutlinedIcon />,
    });

    const buttons: IButtonInfo[] = [
        {
            label: "Criar Usuário",
            stateKey: "showCreateUserForm",
            icon: <AddReactionOutlinedIcon />,
        },
        {
            label: "Excluir Usuário",
            stateKey: "showDeleteUserList",
            icon: <PersonOffOutlinedIcon />,
        },
        {
            label: "Adicionar Usuário",
            stateKey: "showAddUsersComponent",
            icon: <PersonAddAltOutlinedIcon />,
        },
        {
            label: "Remover Usuário",
            stateKey: "showRemoveUserFromGroupComponent",
            icon: <PersonRemoveOutlinedIcon />,
        },
    ];

    const handleButtonClick = (index: number) => {
        if (buttons[index].stateKey === "showAddUsersComponent") {
            setShowAddUsersComponent(true);
        }
        setActiveButtonIndex(buttons[index]);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <Dialog
                sx={{
                    "& .MuiPaper-root": {
                        maxWidth: "none",
                    },
                }}
                open={openDialog}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        padding: "0",
                        minWidth: "400px",
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            padding: "16px 24px 0px 24px ",
                        }}
                        variant="subtitle2"
                    >
                        Gerenciar Usuários
                    </Typography>
                </DialogTitle>
                <DialogActions
                    sx={{
                        padding: "0px 8px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        paddingBottom: "5px",
                        borderBottom: `.5px solid ${theme.palette.secondary.light}`,
                    }}
                >
                    {buttons.map((button, index) => (
                        <Tooltip title={button.label}>
                            <IconButton
                                key={index}
                                onClick={() => handleButtonClick(index)}
                                sx={{
                                    color: theme.palette.primary.main,
                                    backgroundColor:
                                        activeButton?.stateKey ===
                                        button.stateKey
                                            ? "rgba(0, 0, 0, 0.04)"
                                            : "none",
                                }}
                                size="large"
                            >
                                {button.icon}
                            </IconButton>
                        </Tooltip>
                    ))}
                </DialogActions>
                <DialogContent>
                    {activeButton?.stateKey === "showCreateUserForm" && (
                        <CreateUserForm />
                    )}
                    {activeButton?.stateKey ===
                        "showRemoveUserFromGroupComponent" && (
                        <RemoveUserFromGroupList group="Tamarindo" />
                    )}
                    {activeButton?.stateKey === "showDeleteUserList" && (
                        <DeleteUserList />
                    )}
                </DialogContent>
            </Dialog>
            {activeButton?.stateKey === "showAddUsersComponent" && (
                <AddUserToGroupDialog
                    group="Tamarindo"
                    openDialog={showAddUsersComponent}
                    setOpenDialog={setShowAddUsersComponent}
                />
            )}
        </div>
    );
};
