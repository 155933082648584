import { useEffect, useRef, useState } from "react";

import { usePopup } from "./usePopup";
import { Token } from "./useAuth";

import { SDR_WS } from "../settings";

interface ILead {
    lead_information_id: string;
    rdstation_user_name: string;
    rdstation_stage_nickname: string;
}

interface IPercentageMessage {
    percentage: number;
}

interface IInfoMessage {
    message: string;
}

interface IErrorMessage {
    error_message: {
        message: string;
        error: string;
        object: ILead;
    };
}

interface IRowToChangeMessage {
    rowsToChange: ILead[];
}

interface IRowsToIgnore {
    rowsToIgnore: ILead[];
}

type IEndingProcessMessage = {
    results: ILead[] | [];
    errorQuantity: number;
    registers: number[];
    success_quantity: number;
} & (
    | {
          status: "fail";
          reason: string;
      }
    | {
          status: "success";
      }
);

type MessageDataType =
    | IErrorMessage
    | IInfoMessage
    | IPercentageMessage
    | IRowToChangeMessage
    | IRowsToIgnore
    | IEndingProcessMessage;

export const useChangeLeadsUsers = () => {
    const [isReady, setIsReady] = useState(false);
    const [processingData, setProcessingData] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [errorsArray, setErrorsArray] = useState<any[]>([]);
    const [rowsToChange, setRowsToChange] = useState<ILead[]>([]);
    const [rowsToChangeReceived, setRowsToChangeReceived] = useState(false);
    const [rowsToIgnore, setRowsToIgnore] = useState<ILead[]>([]);
    const [rowsToIgnoreReceived, setRowsToIgnoreReceived] = useState(false);
    const [canConfirmExecution, setCanConfirmExecution] = useState(false);

    const { addPopup } = usePopup();

    const ws = useRef<WebSocket | null>(null);

    useEffect(() => {
        if (rowsToChangeReceived && rowsToIgnoreReceived) {
            setCanConfirmExecution(true);
            addPopup({
                title: "Agora selecione o título da tarefa.",
                type: "info",
                duration: "temporary",
            });
        }
    }, [rowsToChangeReceived, rowsToIgnoreReceived, addPopup]);

    useEffect(() => {
        let socket: WebSocket;

        const connect = () => {
            socket = new WebSocket(
                `${SDR_WS}/leads/change-user?Authentication=Bearer ${Token()}`
            );

            socket.onopen = () => setIsReady(true);

            socket.onclose = () => {
                setIsReady(false);
                setProcessingData(false);
            };

            socket.onerror = () => {
                addPopup({
                    type: "error",
                    title: "Conexão com WebSocket falhou!",
                    duration: "temporary",
                });
                socket?.close();
            };

            socket.onmessage = (event) => {
                if (event.data) {
                    try {
                        const data: MessageDataType = JSON.parse(event.data);
                        if ("rowsToChange" in data) {
                            setRowsToChange(data.rowsToChange);
                            setRowsToChangeReceived(true);
                        }
                        if ("rowsToIgnore" in data) {
                            setRowsToIgnore(data.rowsToIgnore);
                            setRowsToIgnoreReceived(true);
                        }
                        if ("error_message" in data) {
                            let message = data.error_message;
                            setErrorsArray((oldState) => [
                                message,
                                ...oldState,
                            ]);
                        }
                        if ("percentage" in data)
                            setPercentage(data.percentage);
                        if ("message" in data) {
                            addPopup({
                                title: data.message,
                                type: "info",
                                duration: "permanent",
                            });
                        }
                        if ("status" in data) {
                            switch (data.status) {
                                case "success":
                                    addPopup({
                                        title: "Processo finalizado com sucesso.",
                                        description: `Houveram ${data.success_quantity} sucessos e ${data.errorQuantity} erros. Todos os erros poderão ser consultados no log de erros.`,
                                        type: "success",
                                        duration: "permanent",
                                    });
                                    break;
                                case "fail":
                                    addPopup({
                                        title: "Falha ao realizar processo.",
                                        description:
                                            data.reason === "multiple errors"
                                                ? "Ocorreram muitos erros em seguida, todos poderão ser consultados no log de erros."
                                                : "",
                                        type: "error",
                                        duration: "permanent",
                                    });
                                    break;
                            }
                            setProcessingData(false);
                        }
                    } catch {
                        addPopup({
                            type: "error",
                            title: "Falha ao gerar planilha ou receber dados.",
                            duration: "temporary",
                        });
                        setProcessingData(false);
                        ws.current?.close();
                    }
                }
            };

            ws.current = socket;
        };
        connect();

        return () => {
            ws.current?.close();
        };
    }, [addPopup]);

    const send = ws.current?.send.bind(ws.current);

    return {
        isReady,
        send,
        setProcessingData,
        processingData,
        percentage,
        errorsArray,
        rowsToChange,
        rowsToIgnore,
        canConfirmExecution,
    };
};
