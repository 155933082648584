import { Token } from '../hooks/useAuth';
import { api } from '../utils/Api/apiClient';

class RestaurantsService {
  url: string;
  constructor() {
    this.url = `api/sdr/restaurants/without-delivery-region/`;
  }

  getRestaurantsWithoutDeliveryArea = (params?: Object) =>
    api
      .get(this.url, {
        headers: {
          Authorization: `Bearer ${Token()}`
        },
        params: params
      })
      .then(res => res.data);
}

export default RestaurantsService;
