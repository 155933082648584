import React, { useRef, useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PendingIcon from '@mui/icons-material/Pending';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useWebSocket } from '../../../hooks/useWebSocket';
import { useSpreadSheet } from '../../../hooks/useSpreadSheet';
import { TemplateData } from '../../../mock/templateData';

import Button from '../../../components/Button';
import InfoCard from '../../../components/InfoCard';
import ServiceTitle from '../../../components/ServiceTitle';
import { ErrorsDialog } from '../../../components/ErrorsDialog';
import FloatingButton from '../../../components/FloatingButton';

import './styles.css';
import ComponentHeader from 'components/ComponentHeader';

const Enrichment = () => {
  const uploadFile = useRef<any>();
  const [openErrorsDialog, setOpenErrorsDialog] = useState(false);

  const { isReady, send, setProcessingData, processingData, monthlySearches, percentage, errorsArray } = useWebSocket();

  const { exportToExcel, creatingExcel } = useSpreadSheet();

  const onDownloadClick = () => {
    exportToExcel(TemplateData, 'Enriquecimento de leads - exemplo de entrada');
  };

  const onFileUpload = (event: React.ChangeEvent) => {
    try {
      setProcessingData(true);
      const reader = new FileReader();

      const files = (event.target as HTMLInputElement).files;
      if (files) {
        reader.readAsArrayBuffer(files[0]);
      }

      reader.onload = event => {
        let rawData = event.target?.result;
        if (typeof event.target?.result !== 'undefined') {
          rawData = event.target?.result;
          if (isReady && rawData) {
            send?.(rawData);
          }
        }
      };

      reader.onloadend = () => {};
    } catch {}
  };

  return (
    <div>
      <ComponentHeader.Root>
        <ComponentHeader.Title text='Enriquecimento dos leads' />
        <ComponentHeader.Desc text='Enriquece a planilha de dados utilizando o serviço Google Places.' />
      </ComponentHeader.Root>
      <Box className={'contentContainer'}>
        <Typography variant='body1'></Typography>
        <Box className={'infoContainer'}>
          <InfoCard
            icon={
              isReady ? (
                <SensorsIcon fontSize='large' sx={{ color: 'primary.dark' }} />
              ) : (
                <SensorsOffIcon fontSize='large' sx={{ color: 'error.main' }} />
              )
            }
            title='Status de Conexão'
            value={isReady ? 'CONECTADO' : 'DESCONECTADO'}
          />
          <InfoCard
            icon={<SupervisedUserCircleIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Pesquisas mensais'
            value={monthlySearches.toString()}
          />

          <InfoCard
            icon={<PendingIcon fontSize='large' sx={{ color: 'primary.dark' }} />}
            title='Progresso'
            value={`${percentage.toFixed(2).toString() || 0} %`}
          />

          <Box className={'buttonsContainer'}>
            <Button
              loading={creatingExcel}
              onClick={onDownloadClick}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              <Typography variant='body2'>EXEMPLO DE ENTRADA</Typography>
            </Button>
            <Button
              startIcon={<CloudUploadOutlinedIcon fontSize='large' />}
              loading={processingData}
              loadingPosition='start'
              onClick={() => {
                uploadFile.current.click();
              }}
              disabled={!isReady}
            >
              <Typography variant='body2'>IMPORTAR PLANILHA</Typography>
            </Button>
          </Box>
          <input
            style={{ display: 'none' }}
            id='filter-lead'
            type='file'
            ref={uploadFile}
            onChange={event => onFileUpload(event)}
          />
        </Box>
        {errorsArray.length > 0 && (
          <FloatingButton aria-label='info' onClick={() => setOpenErrorsDialog(true)}>
            <InfoOutlinedIcon />
          </FloatingButton>
        )}
      </Box>
      <ErrorsDialog errors={errorsArray} openDialog={openErrorsDialog} setOpenDialog={setOpenErrorsDialog} />
    </div>
  );
};

export default Enrichment;
