import { Typography, Box } from "@mui/material";

interface IServiceTitle {
  title: string;
}

const ServiceTitle = ({ title }: IServiceTitle) => {
  return (
    <>
      <Box
        sx={{
          width: "fit-content",
          height: "3.13rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "secondary.main",
            display: "flex",
            alignItems: "center",
            "&.MuiTypography-root::before": {
              content: `""`,
              display: "inline-block",
              backgroundColor: "primary.main",
              width: 8,
              height: 50,
              marginRight: "1.5rem",
              borderRadius: 5,
            },
          }}
        >
          {title.toUpperCase()}
        </Typography>
      </Box>
    </>
  );
};

export default ServiceTitle;
